// @ts-nocheck
import Vue from "vue";
import VueRx from "vue-rx";
import VueJWT from "vuejs-jwt";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import VueMoment from "vue-moment";
import VueCookie from "vue-cookie";
import VueD3 from "vue-d3";

import ElementUI from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import "./dark-theme/index.css";
import "canvg/dist/browser/canvg.min.js";

import _ from "lodash";
import "es6-promise/auto";

import auth from "./auth";
import util from "./util";
import store from "./store";

import App from "./App.vue";
import Login from "./components/Login.vue";
import Dashboard from "./components/Dashboard.vue";
import Overview from "./components/Overview.vue";
import Splash from "./components/Splash.vue";
import Surveillance from "./components/Surveillance.vue";
import FloorMap from "./components/FloorMap.vue";
import Clients from "./components/Clients/Page.vue";
import Client from "./components/Clients.vue";
import ClientService from "./services/ClientService";
import Threshold from "./components/Threshold.vue";
import HandHygiene from "./components/HH/Page.vue";
import OutbreakSetup from "./components/OutbreakSetup.vue";
import OutbreakSummary from "./components/OM/OutbreakSummary.vue";
import OutbreakDetail from "./components/OM/OutbreakDetail.vue";
// import InfectionAnalysis from './components/InfectionAnalysis.vue'
import Antibiotic from "./components/Antibiotic.vue";
import Locations from "./components/Locations.vue";
import HelpPage from "./components/HelpPage.vue";
import InvalidUserPage from "./components/InvalidUserPage.vue";
import ConfigurationService from "./services/ConfigurationService";
import Contacts from "./components/Contacts.vue";
import LabResults from "./components/LabResults.vue";
import PPE from "./components/PPE/Page.vue";
import UAM from "./components/UAM/Page.vue";
import Analysis from "./components/IA/Page.vue";
import MedicationUsage from "./components/IA/MedicationUsage/Page.vue";
import DaysOfTherapy from "./components/IA/DaysOfTherapy/Page.vue";
import InfectionCaseFrequency from "./components/IA/InfectionCaseFrequency/Page.vue";
import InfectionRates from "./components/IA/InfectionRates/Page.vue";
import ImmunizationRates from "./components/IA/ImmunizationRates/Page.vue";
import IsolationDays from "./components/IA/IsolationDays/Page.vue";
import SymptomDetails from "./components/IA/SymptomDetails/Page.vue";
import InfectionAI from "./components/IA/InfectionAI/Page.vue";

window.CONFIG = {};

window.CONFIG.host = process.env.VUE_APP_HOST;
window.CONFIG.host_port = process.env.VUE_APP_HOST_PORT;
window.CONFIG.api = process.env.VUE_APP_API;
window.CONFIG.authHost = process.env.VUE_APP_AUTH_HOST;
window.CONFIG.allowed = process.env.VUE_APP_ALLOWED.split(" ");
window.CONFIG.authServerUrl = `${process.env.VUE_APP_AUTH_HOST}/protocol/openid-connect/auth?${process.env.VUE_APP_KC_SCOPE ? "scope=" + process.env.VUE_APP_KC_SCOPE : ""}&client_id=rest&response_type=token&redirect_uri=`;
Vue.use(VueJWT, {
  signKey: process.env.VUE_APP_PKEY
});

window.CONFIG.sls_api = process.env.VUE_APP_SLS_API;
window.CONFIG.ws_api = process.env.VUE_APP_WS_API;

window.CONFIG.ppe_api = `${window.CONFIG.sls_api}/ppe`;
window.CONFIG.hh_api = `${window.CONFIG.sls_api}/handhygiene`;
window.CONFIG.uam_api = `${window.CONFIG.sls_api}/uam`;
window.CONFIG.wss_api = `${window.CONFIG.ws_api}/report`;
window.CONFIG.report_api = `${window.CONFIG.sls_api}/document/report/generate`;
window.CONFIG.treatement_api = `${window.CONFIG.sls_api}/treatments`;
window.CONFIG.infection_api = `${window.CONFIG.sls_api}/infection-cases`;
window.CONFIG.om_api = `${window.CONFIG.sls_api}/outbreaks`;
window.CONFIG.precaution_api = `${window.CONFIG.sls_api}/precautions`;
window.CONFIG.immunization_api = `${window.CONFIG.sls_api}/immunizations`;
window.CONFIG.client_api = `${window.CONFIG.sls_api}/clients`;
window.CONFIG.infection_ai_api = `${window.CONFIG.sls_api}/infection-ai`;
window.CONFIG.pcc_integration_api = `${window.CONFIG.sls_api}/pcc-integration`;

window.CONFIG.my_audits_host = process.env.VUE_APP_MY_AUDITS_HOST;
window.CONFIG.my_audits_port = process.env.VUE_APP_MY_AUDITS_HOST_PORT;

window.CONFIG.ipac_web_host = process.env.VUE_APP_IPAC_HOST;
window.CONFIG.ipac_web_port = process.env.VUE_APP_IPAC_PORT;

window.CONFIG.loginCallbackUrl = window.CONFIG.host + window.CONFIG.host_port + "/token/";
window.CONFIG.authServerUrl = window.CONFIG.authServerUrl + window.CONFIG.loginCallbackUrl;

if (process.env.VUE_APP_DISABLE_LOG === "Y") {
  window.console.realLog = window.console.log;
  window.console.log = function() {};
}

Vue.use(VueRx);
Vue.use(VueResource);
Vue.use(VueCookie);
Vue.use(ElementUI);
Vue.use(VueMoment);
Vue.use(VueD3);
Vue.use(VueRouter);
Vue.use(ClientService);

// configure language
locale.use(lang);

Vue.use(ConfigurationService);

Vue.http.headers.common["Content-Type"] = "application/json";
// Vue.http.headers.common['Access-Control-Allow-Origin'] = window.CONFIG.host + window.CONFIG.host_port
Vue.http.headers.common["Accept"] = "application/json, text/plain, */*";

Vue.http.options.xhr = { withCredentials: true };
Vue.http.interceptors.push(function(request, next) {
  // modify method
  // request.method = 'POST'

  // modify headers
  // request.headers.set('X-CSRF-TOKEN', 'TOKEN')
  if (auth.loggedIn()) {
    // request.headers.set('X-CSRF-TOKEN', auth.getToken())
    request.headers.set("Authorization", "Bearer " + auth.getToken());
  }
  request.headers.set("tz", Intl.DateTimeFormat().resolvedOptions().timeZone);

  // request.headers.set('cache-control', 'no-cache')

  // console.log(JSON.stringify(request.headers, ' ', 1))
  // continue to next interceptor
  next(function(response) {
    if (response.status === 401) {
      console.log("401, logout");
      auth.logout(function() {
        window.location = "/";
      });
    }
    return response;
  });
});

function requireAuth(to, from, next) {
  if (!auth.loggedIn()) {
    console.log(Vue);
    let idp = Vue.cookie.get("idp");
    console.log("IDP", idp);
    window.location = window.CONFIG.authServerUrl + (idp ? "&kc_idp_hint=" + idp : "");
  } else {
    next();
  }
}

function getQueryVariable(query, variable) {
  var vars = query.split("&");
  var result = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return result;
}

function hhonly() {
  return auth.userInfo().org === "arnprior" || auth.userInfo().org === "cch" || Vue.$configStore.hhOnly();
}

function updateToken(to, from, next) {
  var tokenObj = getQueryVariable(to.hash.substring(1));
  console.log(JSON.stringify(tokenObj, " ", 2));
  auth.updateTokens(tokenObj.access_token, tokenObj.refresh_token, function() {
    auth.updateTokens(tokenObj.access_token);
    console.log(JSON.stringify(auth.userInfo(), " ", 2));
    if (auth.userInfo() && !auth.userInfo().org) {
      next({ path: "/invalid" });
    } else if (auth.userInfo().roles.indexOf("ROLE_APP_ONLY") >= 0) {
      auth.logout();
    } else if (auth.userInfo().roles.indexOf("ROLE_USER") < 0 && auth.userInfo().roles.indexOf("ROLE_READ_ONLY_USER") < 0) {
      console.log("no role, logout");
      auth.logout();
    } else if (window.CONFIG.allowed.indexOf(auth.userInfo().org) > -1) {
      console.log("org " + auth.userInfo().org);
      console.log("IDP SET", auth.userInfo().idp);
      Vue.cookie.set("idp", auth.userInfo().idp);
      if (Vue.$configStore && Vue.$configStore.loaded) {
        if (hhonly()) {
          console.log("move to hh");
          next({ path: "/hh" });
        } else {
          console.log("move to /");
          next({ path: "/" });
        }
      }
    } else if (auth.userInfo().username === "apple.demo") {
      next({ path: "/" });
    } else {
      auth.logout();
    }
    // })
  });
}

var router = new VueRouter({
  hashbang: false,
  mode: "history",
  root: "/",
  routes: [
    { path: "/labresults", component: LabResults, beforeEnter: requireAuth },
    { path: "/overview", component: Overview, beforeEnter: requireAuth },
    { path: "/splash", component: Dashboard, beforeEnter: requireAuth },
    { path: "/", component: Splash, beforeEnter: requireAuth },
    {
      path: "/surveillance",
      component: Surveillance,
      beforeEnter: requireAuth
    },
    { path: "/floormap", component: FloorMap, beforeEnter: requireAuth },
    { path: "/token", beforeEnter: updateToken },
    { path: "/login", component: Login },
    { path: "/clients", component: Clients, beforeEnter: requireAuth, props: { isStaffPage: false } },
    { path: "/staff", component: Clients, beforeEnter: requireAuth, props: { isStaffPage: true } },
    { path: "/client-old", component: Client, beforeEnter: requireAuth },
    { path: "/threshold", component: Threshold, beforeEnter: requireAuth },
    { path: "/hh", component: HandHygiene, beforeEnter: requireAuth },
    { path: "/setup-outbreak", component: OutbreakSetup, beforeEnter: requireAuth },
    { path: "/om", component: OutbreakSummary, beforeEnter: requireAuth },
    { path: "/om/:outbreakId", component: OutbreakDetail, name: "OutbreakDetail", beforeEnter: requireAuth, props: true },
    { path: "/analysis", component: Analysis, beforeEnter: requireAuth },
    { path: "/antibiotic", component: Antibiotic, beforeEnter: requireAuth },
    { path: "/locations", component: Locations, beforeEnter: requireAuth },
    { path: "/contacts", component: Contacts, beforeEnter: requireAuth },
    { path: "/ppe", component: PPE, beforeEnter: requireAuth },
    { path: "/uam", component: UAM, beforeEnter: requireAuth },
    {
      path: "/analysis/medication-usage",
      component: MedicationUsage,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/days-of-therapy",
      component: DaysOfTherapy,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/case-frequency",
      component: InfectionCaseFrequency,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/infection-rates",
      component: InfectionRates,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/immunization-rates",
      component: ImmunizationRates,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/isolation-days",
      component: IsolationDays,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/symptom-details",
      component: SymptomDetails,
      beforeEnter: requireAuth
    },
    {
      path: "/analysis/infection-ai",
      component: InfectionAI,
      beforeEnter: requireAuth
    },
    { path: "/help", name: "help", component: HelpPage },
    {
      path: "/invalid",
      name: "invalid",
      component: InvalidUserPage,
      props: route => ({ idp_type: route.query.idp_type })
    }
  ]
});

Vue.mixin({
  methods: {
    offsetDate: function(v) {
      return util.offsetDate(v);
    }
  }
});

// el-select elements that use filterable attr: clear filter input on change
Vue.mixin({
  mounted() {
    if (this.$options.name === 'ElSelect' && this.filterable) {
      this.$on('change', () => {
        this.query = '';
      });
    }
  }
});

// Vue.config.ignoredElements = ['hcx-floormap']

Object.defineProperty(Vue.prototype, "$_", { value: _ });

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  render: h => h(App)
});
