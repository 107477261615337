<template>
  <div
    class="antibiotic"
    style="
    width: 100%;
    height: 100%;"
  >
    <div class="filter">
      <div class="section-header">
        <el-row><span>Antibiotic Stewardship</span></el-row>
      </div>
      <el-row v-if="enterprise" class="filter-group-label">Location Filter:</el-row>
      <el-row class="filter-section" v-if="enterprise">
        <label v-if="enterprise"
          ><span class="filter-title-label">{{ category ? category.subCategoryText : "" }}</span>
          <el-select v-model="selectedCategory" placeholder="Province" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in category.subCategory" :label="item.displayText" v-bind:key="item.id" :value="item"> </el-option>
          </el-select>
        </label>
        <label v-if="selectedCategory != 0 && selectedCategory.subCategory && selectedCategory.subCategory.length > 0"
          ><span class="filter-title-label">{{ selectedCategory.subCategoryText }}</span>
          <el-select v-model="selectedSubCategory" placeholder="Region" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in selectedCategory.subCategory" :label="item.displayText" v-bind:key="item.id" :value="item"> </el-option>
          </el-select>
        </label>
        <label
          ><span class="filter-title-label">Type</span>
          <el-select v-model="selectedFacType" placeholder="Type" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="LTC" value="LTC"> </el-option>
            <el-option label="RET" value="RET"> </el-option>
          </el-select>
        </label>
        <label
          ><span class="filter-title-label">Facility</span>
          <el-select v-model="selectedSite" placeholder="Facility" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in filteredSites" :label="item.displayText" v-bind:key="item.id" :value="item">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label
          ><span class="filter-title-label">Unit</span>
          <el-select v-model="selectedUnit" placeholder="Type" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in filteredUnits" :label="item.displayText" v-bind:key="item.id" :value="item">
              <span style="float: left">{{ item.displayText }} - {{ item.siteDisplayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
      </el-row>
      <el-row v-if="enterprise" class="filter-group-label" style="margin-top: 1em;">
        Antibiotic Filter:
      </el-row>
      <el-row class="filter-section">
        <label
          ><span class="filter-title-label">Infection Site</span>
          <el-select v-model="infectionTypeFilter" placeholder="All" class="filter-section-input multi-select-item-filter" v-on:change="updateInfectionStieSelection" multiple clearable>
            <el-option v-for="i in availableItemTypes" v-bind:key="i" :label="i" :value="i"> </el-option>
          </el-select>
        </label>
        <label
          ><span class="filter-title-label">Antibiotic</span>
          <el-select v-model="antibioticTypeFilter" placeholder="All" class="filter-section-input multi-select-item-filter" v-on:change="reloadPage" multiple clearable>
            <el-option v-for="i in availableAntibioticItemTypes" v-bind:key="i" :label="i" :value="i"> </el-option>
          </el-select>
        </label>
        <label v-if="displayType !== 0"
          ><span class="filter-title-label">Collection Date</span>
          <el-date-picker class="filter-section-input" label="Collection Date" v-model="filterDateRange" type="datetimerange" placeholder="Collection Date Range" :format="$configStore.datePickerDateFormat()" range-separator=" ~ " :picker-options="pickerOptions" :clearable="false" v-on:change="updateFilter" small>
            <template slot="prepend">Http://</template>
          </el-date-picker>
        </label>
        <label v-if="!enterprise && displayType !== 0"
          ><span class="filter-title-label">Facility</span>
          <el-select v-model="selectedSite" placeholder="Facility" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in filteredSites" :label="item.displayText" v-bind:key="item.id" :value="item">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label v-if="!enterprise && displayType !== 0"
          ><span class="filter-title-label">Unit</span>
          <el-select v-model="selectedUnit" placeholder="Type" class="filter-section-input" v-on:change="updateFilter">
            <el-option label="All" :value="0"> </el-option>
            <el-option v-for="item in filteredUnits" :label="item.displayText" v-bind:key="item.id" :value="item">
              <span style="float: left">{{ item.displayText }} - {{ item.siteDisplayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label v-if="!enterprise && displayType !== 0"
          ><span class="filter-title-label">Treated</span>
          <el-select v-model="treatedFilter" placeholder="Type" class="filter-section-input" v-on:change="reloadPage">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="Yes" :value="1"> </el-option>
            <el-option label="No" :value="2"> </el-option>
          </el-select>
        </label>
      </el-row>
    </div>
    <!--<div class="graph">
      <temp-chart :width=1500></temp-chart>
    </div>-->
    <div class="section-header" v-if="displayType !== 0">
      <el-row>
        Graph {{ displayType == "infection" ? "By Infection Site" : "By Antibiotic" }}
        <div class="graph-filter">
          <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportGraph()">Export Graph</el-button>
          <!-- <el-radio-group v-model="graphPeriodFilter" size="mini">
            <el-radio-button label="By Year"></el-radio-button>
            <el-radio-button label="By Quarter"></el-radio-button>
            <el-radio-button label="By Month"></el-radio-button>
            <el-radio-button label="By Week"></el-radio-button>
          </el-radio-group> -->
          <el-radio-group v-model="graphPeriodFilter" size="mini">
            <el-radio-button label="By Year"></el-radio-button>
            <el-radio-button label="By Quarter"></el-radio-button>
            <el-radio-button label="By Month"></el-radio-button>
            <!-- <el-radio-button label="By Week"></el-radio-button> -->
          </el-radio-group>
          <el-radio-group v-model="graphDisplayFilter" size="mini">
            <el-radio-button label="By Antibiotic"></el-radio-button>
            <el-radio-button label="By Infection Site"></el-radio-button>
          </el-radio-group>
        </div>
      </el-row>
    </div>
    <div class="graph" v-if="displayType !== 0">
      <antibiotic-bar-graph ref="antibioticGraph" :data="d3Data || []" :mode="displayType" :range="filterDateRange" :period="filterPeriods" :sites="filterSymptomTypes" :origin="filterOrigin" :confirmed="true" :config="graphConfig" :unitId="selectedUnit"></antibiotic-bar-graph>
    </div>
    <div class="section-header" v-if="displayType !== 0">
      <el-row
        ><span>Report {{ displayType == "infection" ? "By Infection Site" : "By Antibiotic" }} </span>
        <div class="graph-filter">
          <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="printReport()">Print Report</el-button>
        </div>
      </el-row>
    </div>
    <el-dialog :title="observationDialogTitle" v-if="copiedObservation" v-model="copyDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="observerForm" :model="copiedObservation" label-width="160px">
        <el-form-item label="Observer" prop="observerName" :rules="[{ required: true, message: 'Observer is required' }]">
          <el-select v-model="copiedObservation.observerName" placeholder="Observer" class="filter-section-input">
            <el-option v-for="item in observers" v-bind:key="item.id" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Facility" prop="facId" :rules="[{ required: true, message: 'Facility is required' }]">
          <el-select v-model="copiedObservation.facId" placeholder="Facility" class="filter-section-input" v-on:change="updateEditUnits">
            <el-option v-for="item in sites" v-bind:key="item.id" :label="item.displayText" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Unit" prop="unitId" :rules="[{ required: true, message: 'Unit is required' }]">
          <el-select v-model="copiedObservation.unitId" placeholder="Unit" class="filter-section-input" :disabled="!copiedObservation.facId" v-on:change="editUnitSelected">
            <el-option v-for="item in units" v-if="item.siteId == copiedObservation.facId" v-bind:key="item.id" :label="item.displayText" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HCW Type" prop="workerType" :rules="[{ required: true, message: 'HCW Type is required' }]">
          <el-select v-model="copiedObservation.workerType" placeholder="HCW Type" class="filter-section-input">
            <el-option v-for="item in hhSettings.workers" v-bind:key="item.id" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Moment" prop="moment" :rules="[{ required: true, message: 'Moment is required' }]">
          <el-select v-model="copiedObservation.moment" placeholder="Moment" class="filter-section-input">
            <el-option v-for="item in hhSettings.moments" v-bind:key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Outcome" prop="outcome.outcome" :rules="[{ required: true, message: 'Outcome is required' }]">
          <el-radio-group v-model="copiedObservation.outcome.outcome" class="filter-section-input">
            <el-radio-button v-for="item in hhSettings.outcomeTypes" v-bind:key="item" :label="item">{{ item }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="copiedObservation.outcome.outcome !== 'Missed' && copiedObservation.outcome.outcome" label="Second(s)">
          <el-input-number v-model="copiedObservation.outcome.seconds" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="Collection Date" prop="collectionDate" :rules="[{ required: true, message: 'Collection Date is required' }]">
          <el-date-picker v-model="copiedObservation.collectionDate" type="datetime" placeholder="Collection Date" format="dd-MM-yyyy HH:mm" editable clearable> </el-date-picker>
        </el-form-item>
        <el-form-item label="Custom Types">
          <el-checkbox v-for="customType in copiedObservation.customTypes" v-model="customType.checked" v-bind:key="customType.type">{{ customType.type }}</el-checkbox>
        </el-form-item>
        <br />
        <el-form-item label="Comments">
          <el-input type="textarea" :rows="2" placeholder="Comments" v-model="copiedObservation.comments"> </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            copyDialogVisible = false;
            copiedObservation = null;
          "
          >Cancel</el-button
        >
        <el-button @click="update" v-if="isUpdate" type="primary" :disabled="!allowedToEdit()">Update</el-button>
        <el-button @click="create" v-else type="primary" :disabled="!allowedToEdit()">Add</el-button>
      </span>
    </el-dialog>
    <div v-if="displayType !== 0 && reportDisplayData"></div>
    <div ref="antibioticReport">
      <div v-if="categorizedCases && displayType !== 0">
        <div class="section-header section-header-sub" v-for="cases in categorizedCases" v-bind:key="cases.key">
          <el-row
            ><span>{{ cases.key }}</span></el-row
          >
          <el-table :data="cases.cases" style="width: 100%" v-if="cases.cases.length > 0" :row-class-name="tableRowClassName">
            <el-table-column :label="displayType === 'antibiotic' ? 'Infection Site' : 'Antibiotic'">
              <template slot-scope="scope">
                {{ scope.row.key }}
              </template>
            </el-table-column>
            <el-table-column label="Percentage (DOT)" width="200" class-name="number-cell">
              <template slot-scope="scope">
                <div v-if="scope.row.dot">{{ scope.row.dot }}%</div>
              </template>
            </el-table-column>
            <el-table-column class-name="number-cell" label="Total Cases" width="200">
              <template slot-scope="scope">
                {{ scope.row.total }}
              </template>
            </el-table-column>
            <el-table-column class-name="number-cell" label="Total DOT" width="200">
              <template slot-scope="scope">
                {{ scope.row.totalDot }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AntibioticBarGraph from "../components/AntibioticBarGraph";
import InfCaseBarGraph from "../components/InfCaseBarGraph";

import SymptomCaseDetail from "../components/SymptomCaseDetail";
import SurveillanceCaseDetail from "../components/SurveillanceCaseDetail";
import { Loading } from "element-ui";
import auth from "../auth";
import util from "../util";

import moment from "moment";

export default {
  name: "antibiotic",
  components: {
    "antibiotic-bar-graph": AntibioticBarGraph,
    "inf-case-bar-graph": InfCaseBarGraph,
    "symptom-case-detail": SymptomCaseDetail,
    "surveillance-case-detail": SurveillanceCaseDetail
  },
  data: function() {
    return {
      d3Data: [],
      categorizedCases: [],
      cases: [],
      itemTypeFilter: [],
      antibioticTypeFilter: [],
      infectionTypeFilter: [],
      availableItemTypes: [],
      availableAntibioticItemTypes: [],
      reportDisplayData: [],
      treatedFilter: 0,
      displayType: "infection",
      selectedCategory: 0,
      selectedSubCategory: 0,
      category: {
        subCategory: []
      },
      groupedBy: 0,
      selectedWorker: 0,
      selectedMoment: 0,
      selectedOutcome: 0,
      outcomeTypes: [],
      showDeleted: 0,
      copiedObservation: null,
      dataReady: false,
      filterInfoStirng: "",
      searchObserver: "",
      displayDateRange: "",
      hhSettings: {
        workers: [],
        outcomeTypes: [],
        moments: [],
        customTypes: ["", "", "", "", "", "", "", "", "", "", "", ""]
      },
      sites: [],
      filteredSites: [],
      observers: [],
      observationDialogTitle: "Copy Observation",
      observations: [],
      filteredObservations: [],
      pagedObservations: [],
      isUpdate: false,
      isNew: false,
      initialRun: true,
      loadingData: false,
      enterprise: false,
      loadingView: null,
      currentDate: new Date(),
      precautions: [],
      beds: {},
      symptomTypes: [],
      searchNameInput: "",
      graphConfig: {},
      graphMode: "site",
      clients: {},
      selectedUnit: 0,
      selectedSite: 0,
      selectedGroup1: 0,
      selectedGroup2: 0,
      selectedGroup3: 0,
      selectedFacType: 0,
      selectedOrigin: 0,
      copyDialogVisible: false,
      selectedStatus: 0,
      symptomDetailPage: 1,
      symptomDetailPageSize: 10,
      symptomDetailPageTotal: 0,
      symptomDetailPageSizeOptions: [
        {
          label: "5 items per page",
          value: 5
        },
        {
          label: "10 items per page",
          value: 10
        },
        {
          label: "20 items per page",
          value: 20
        },
        {
          label: "50 items per page",
          value: 50
        }
      ],
      filterDateRange: "",
      filteredSite: {},
      filterPeriods: "year",
      filterType: {
        filter: "moment",
        data: []
      },
      filterSymptomTypes: [],
      filterConfirmStatus: null,
      filterOrigin: null,
      organisms: [],
      treatments: [],
      units: [],
      editUnits: [],
      filteredUnits: [],
      symptomTypeMap: {},
      graphPeriodFilter: "By Year",
      graphDisplayFilter: "By Infection Site",
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const time = moment().subtract(1, "weeks");
              const end = time.endOf("week").toDate();
              const start = time.startOf("week").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const lastMonth = moment().subtract(1, "months");
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 90 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment()
                .subtract(90, "days")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last quarter",
            onClick(picker) {
              const lastQ = moment().subtract(1, "quarters");
              const end = lastQ.endOf("quarter").toDate();
              const start = lastQ.startOf("quarter").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last year",
            onClick(picker) {
              const lastYear = moment().subtract(1, "years");
              const end = lastYear.endOf("year").toDate();
              const start = lastYear.startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current week",
            onClick(picker) {
              const time = moment();
              const end = time.endOf("week").toDate();
              const start = time.startOf("week").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current month",
            onClick(picker) {
              const lastMonth = moment();
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current quarter",
            onClick(picker) {
              const end = moment()
                .endOf("quarter")
                .toDate();
              const start = moment()
                .startOf("quarter")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current year",
            onClick(picker) {
              const end = moment()
                .endOf("year")
                .toDate();
              const start = moment()
                .startOf("year")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  watch: {
    symptomDetailPageSize: function(v) {
      this.pageUpdate(1);
    },
    selectedCategory: function(v) {
      if (!this.enterprise) {
        return;
      }

      var vm = this;
      if (v === 0) {
        this.selectedSubCategory = 0;
      }
      vm.$nextTick(function() {
        vm.updateFacilityList();
        vm.updateFilter();
      });
    },
    selectedSubCategory: function(v) {
      if (!this.enterprise) {
        return;
      }
      this.updateFacilityList();
      this.selectedSite = 0;
      this.updateFilter();
    },
    selectedFacType: function(v) {
      if (!this.enterprise) {
        return;
      }
      var vm = this;
      vm.$nextTick(function() {
        this.updateFacilityList();
        this.updateFilter();
      });
    }
  },
  methods: {
    updateInfectionStieSelection: function() {
      this.antibioticTypeFilter = [];
      this.reloadPage();
    },
    printReport() {
      var a = `.el-table__header-wrapper {
            text-align: left;
        }

        table {
            width: 100% !important;
        }

        .cell {
            text-align: left;
        }

        tr.sum-row td {
            margin-top: 0.5em;
            border-top: 1pt solid black !important;
        }

        td {
          padding: 0.2em;
        }

        span {
            font-size: 1.4em;
            font-weigt: bold;
            padding-bottom: 0.5em;
            display: block;
        }`;
      var b = this.$refs.antibioticReport.innerHTML;
      console.log(b);
      var title = "<h2>Report " + (this.displayType === "infection" ? "By Infection Site" : "By Antibiotic") + " (" + this.displayDateRange + ")" + "</h2>";
      window.frames["print_frame"].document.open();
      window.frames["print_frame"].document.write("<style>" + a + "</style><body>" + title + b + "</body>");
      window.frames["print_frame"].document.close();
      window.frames["print_frame"].window.focus();
      // window.frames['print_frame'].document.title = window.document.title
      console.log(window.frames["print_frame"].document.innerHTML);
      window.frames["print_frame"].window.print();
    },
    tableRowClassName(row, index) {
      if (row.key === "Total") {
        console.log("sum-row");
        return "sum-row";
      }
      return "";
    },
    editUnitSelected: function(v) {
      console.log(JSON.stringify(v, 2, " "));
      console.log(JSON.stringify(this.editUnits, 2, " "));
      var vm = this;
      this.editUnits.forEach(function(u) {
        if (u.id === vm.copiedObservation.unitId) {
          vm.copiedObservation.unit = u;
        }
      });
    },
    diffInDays: function(a, b) {
      var d1 = moment(a);
      var d2 = moment(b);
      return d1.diff(d2, "days") + 1;
    },
    updateEditUnits: function() {
      var vm = this;
      vm.editUnits = [];
      var units = this.unitsByFacId(this.copiedObservation.facId);
      units.forEach(function(u) {
        vm.editUnits.push(u);
      });

      if (vm.copiedObservation.unit && vm.copiedObservation.facId !== vm.copiedObservation.unit.siteId) {
        vm.$nextTick(function() {
          vm.copiedObservation.unit = null;
          vm.copiedObservation.unitId = null;
          vm.copiedObservation.unitName = "";
        });
      }
    },
    loadCases: function() {
      var vm = this;
      // this.loadingData = true

      if (this.initialRun) {
        this.initialRun = false;
        vm.$watch("graphPeriodFilter", function(val) {
          if (vm.graphPeriodFilter === "By Year") {
            vm.filterPeriods = "year";
          } else if (vm.graphPeriodFilter === "By Quarter") {
            vm.filterPeriods = "quarter";
          } else if (vm.graphPeriodFilter === "By Month") {
            vm.filterPeriods = "month";
          } else if (vm.graphPeriodFilter === "By Week") {
            vm.filterPeriods = "week";
          }
          vm.summaryData = null;
          vm.$forceUpdate();
          vm.$nextTick(function() {
            // vm.buildSummary()
            vm.reloadPage();
          });
        });

        vm.$watch("graphDisplayFilter", function(val) {
          // console.log(val)
          if (vm.graphDisplayFilter === "By Unit") {
            vm.graphMode = "unit";
          } else {
            vm.graphMode = "site";
          }
          vm.summaryData = null;
          vm.$forceUpdate();
          vm.$nextTick(function() {
            vm.reloadPage();
          });
        });
      }
    },
    updateFacilityList: function() {
      if (!this.enterprise) {
        return;
      }

      var vm = this;
      var facs = [];
      var loadFacs = function(refIds) {
        if (!refIds) {
          return;
        }

        refIds.forEach(function(refId) {
          var s = vm.getSiteWithId(refId);
          if (s) {
            if (vm.selectedFacType !== 0) {
              var isRET = s.displayText.includes(" RET ");
              if (vm.selectedFacType === "RET" && isRET) {
                facs.push(s);
              } else if (vm.selectedFacType !== "RET" && !isRET) {
                facs.push(s);
              }
            } else {
              facs.push(s);
            }
          }
        });
      };

      if (this.selectedCategory === 0) {
        this.sites.forEach(function(s) {
          if (vm.selectedFacType !== 0) {
            var isRET = s.displayText.includes(" RET ");
            if (vm.selectedFacType === "RET" && isRET) {
              facs.push(s);
            } else if (vm.selectedFacType !== "RET" && !isRET) {
              facs.push(s);
            }
          } else {
            facs.push(s);
          }
        });
      } else if (this.selectedSubCategory === 0) {
        loadFacs(this.selectedCategory.refIds);
        this.selectedCategory.subCategory.forEach(function(sub) {
          loadFacs(sub.refIds);
          sub.subCategory.forEach(function(subSub) {
            loadFacs(subSub.refIds);
          });
        });
      } else {
        loadFacs(this.selectedSubCategory.refIds);
      }
      vm.filteredSites = facs;

      var tempUnits = [];
      facs.forEach(function(f) {
        vm.units.forEach(function(u) {
          if (u.siteId === f.id) {
            tempUnits.push(u);
          }
        });
      });
      vm.filteredUnits = tempUnits;
      vm.selectedUnit = 0;
      console.log(JSON.stringify(facs, " ", 2));
      console.log(this.selectedCategory);
    },
    getSiteWithId: function(id) {
      var site = null;
      this.sites.forEach(function(s) {
        if (s.id === id) {
          site = s;
        }
      });
      return site;
    },
    exportGraph: function() {
      var graph = this.$refs.antibioticGraph;
      graph.exportGraph();
    },
    statusImage: function(a) {
      // console.log(a)
    },
    copiedObservationFromRow: function(row) {
      this.copiedObservation = JSON.parse(JSON.stringify(row));
      this.updateEditUnits();

      this.copiedObservation.unit = null;
      var vm = this;
      this.units.forEach(function(u) {
        console.log(u);
        if (u.id === vm.copiedObservation.unitId || (vm.copiedObservation.facId === vm.copiedObservation.facId && vm.copiedObservation.unitId === 0 && u.displayText === vm.copiedObservation.unitName)) {
          vm.copiedObservation.unit = u;
          vm.copiedObservation.unitId = u.id;
        }
        console.log(vm.copiedObservation.unitId);
      });
      var customTypes = [];
      // console.log(JSON.stringify(this.hhSettings, ' ', 2))
      this.hhSettings.customTypes.forEach(function(cType) {
        var customType = {
          type: cType,
          checked: vm.customTypeStatus(vm.copiedObservation, cType)
        };
        customTypes.push(customType);
      });
    },
    selectedRow: function(row, event, column) {
      if (column.property === "active") {
        return;
      }

      if (!row.active) {
        return;
      }
      // console.log(row)
      this.copiedObservationFromRow(row);
      this.isUpdate = true;
      this.copyDialogVisible = true;
      this.observationDialogTitle = "Update Observation";
    },
    pageUpdate: function(page) {
      this.symptomDetailPage = page;
      var start = (this.symptomDetailPage - 1) * this.symptomDetailPageSize;
      var end = start + this.symptomDetailPageSize;
      var list = this.filteredObservations.slice(start, end) || [];
      // this.$set(this.pagedObservations, page)
      var tempPageFilter = [];
      list.forEach(function(o) {
        tempPageFilter.push(o);
      });
      this.pagedObservations = tempPageFilter;
      this.symptomDetailPageTotal = Math.ceil((this.filteredObservations.length || 0) / this.symptomDetailPageSize);
    },
    isHHAdmin: function() {
      return auth.userInfo().roles.indexOf("ROLE_HH_ADMIN") >= 0;
    },
    isWebObserver: function() {
      return auth.userInfo().roles.indexOf("ROLE_HH_WEB_OBSERVER") >= 0;
    },
    allowedToEdit: function() {
      return this.isHHAdmin() || this.isWebObserver();
    },
    buildParam: function() {
      var after = this.filterDateRange[0] || new Date();
      var before = this.filterDateRange[1] || new Date();

      after = moment(after)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .toDate();
      before = moment(before)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate();

      var rUrl = "";
      rUrl = rUrl + "start=" + encodeURIComponent(after) + "&end=" + encodeURIComponent(before) + "&confirmed=true";
      console.log(rUrl);

      return rUrl;
    },
    groupCasesByInfectionSite: function(cases) {
      var vm = this;
      var categorizedCases = {};
      vm.cases = [];
      vm.reportDisplayData = [];
      vm.categorizedCases = [];

      cases.forEach(function(c) {
        // if (c.treatmentDetails && c.treatmentDetails.length > 0 && supported[c.infectionSite]) {
        console.log(c.unitId);
        console.log(vm.selectedUnit);
        if (vm.isInfectinSiteSupprted(c.infectionSite) && (vm.selectedUnit === 0 || vm.selectedUnit.id === c.unitId)) {
          if (vm.infectionTypeFilter.length > 0) {
            var hasMatch = false;
            vm.infectionTypeFilter.forEach(i => {
              hasMatch = hasMatch || i.startsWith(c.infectionSite);
              console.log("=-=-=-=-=-=-=-=-=-=");
              console.log(i);
              console.log(c.infectionSite);
              console.log(hasMatch);
            });
            if (!hasMatch) {
              return;
            }
          }
          if (vm.treatedFilter === 1 && c.treatmentDetails.length <= 0) {
            return;
          } else if (vm.treatedFilter === 2 && c.treatmentDetails.length > 0) {
            return;
          }
          vm.cases.push(c);
          var k = c.infectionSite + (c.subtypeString ? " - " + c.subtypeString : "");
          var reportData = {
            name: c.client.firstName + (c.client.middleName ? " " + c.client.middleName + " " : " ") + c.client.lastName,
            infectionSite: k,
            onSetDate: c.onSetDate,
            organism: c.organismDetails.length > 0 ? c.organismDetails[0].organism.name : ""
          };
          // console.log(JSON.stringify(reportData, ' ', 2))
          // console.log(k)
          if (!categorizedCases[k]) {
            categorizedCases[k] = {};
          }
          c.treatmentDetails.forEach(function(t) {
            if (t.treatment && (vm.treatedFilter === 0 || vm.treatedFilter === 1)) {
              reportData.treatmentDetail = t;
              vm.reportDisplayData.push(util.copyJson(reportData));
              var k2 = t.treatment.displayText;
              var dot = 0;
              var start = t.startDate || c.onSetDate || new Date();
              var end = t.endDate || c.resolutionDate || (c.resolved ? c.updated : null);
              end = end || new Date();

              var after = vm.filterDateRange[0];
              if (after && moment(start).isBefore(moment(after))) {
                start = after;
              }
              var before = vm.filterDateRange[1];
              if (before && moment(end).isAfter(moment(before))) {
                end = before;
              }

              // console.log(k)
              // console.log(k2)
              // console.log(moment(start).toString())
              // console.log(end)
              // console.log(moment(end).toString())
              dot = vm.diffInDays(end, start);
              // console.log('dot: ' + dot)
              if (categorizedCases[k][k2]) {
                categorizedCases[k][k2].total = categorizedCases[k][k2].total + 1;
                categorizedCases[k][k2].dot = categorizedCases[k][k2].dot + dot;
              } else {
                categorizedCases[k][k2] = { total: 1, dot: dot };
              }
            }
          });

          if (c.treatmentDetails.length === 0 && (vm.treatedFilter === 0 || vm.treatedFilter === 2)) {
            vm.reportDisplayData.push(util.copyJson(reportData));
            var dot = 0;
            var start = c.onSetDate || new Date();
            var end = c.resolutionDate || (c.resolved ? c.updated : null);
            end = end || new Date();
            var after = vm.filterDateRange[0];
            if (after && moment(start).isBefore(moment(after))) {
              start = after;
            }
            var before = vm.filterDateRange[1];
            if (before && moment(end).isAfter(moment(before))) {
              end = before;
            }

            // dot = vm.diffInDays(end, start)
            if (categorizedCases[k]["None"]) {
              categorizedCases[k]["None"].total = categorizedCases[k]["None"].total + 1;
            } else {
              // categorizedCases[k]['None'] = { total: 1, dot: dot }
              categorizedCases[k]["None"] = { total: 1, dot: dot };
            }
          }
        }
      });

      var tCases = [];
      for (var p in categorizedCases) {
        var tC = { key: p, cases: [] };
        console.log(p);
        var totalDot = 0;
        var totalCase = 0;
        var target = categorizedCases[p];
        for (var p2 in target) {
          console.log(p2);
          console.log(target[p2].dot);
          totalDot += target[p2].dot;
          totalCase += target[p2].total;
        }

        for (p2 in target) {
          var nC = {
            key: p2,
            dot: p2.toLowerCase() === "none" ? 0 : ((target[p2].dot / totalDot) * 100).toFixed(2),
            total: target[p2].total,
            totalDot: target[p2].dot
          };
          tC.cases.push(nC);
        }

        tC.cases = tC.cases.sort(function(a, b) {
          if (a.key.toLowerCase() === "none") {
            return 1;
          } else if (b.key.toLowerCase() === "none") {
            return -1;
          } else {
            return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
          }
        });

        if (tC.cases.length > 0) {
          console.log(totalDot);
          tC.cases.push({
            key: "Total",
            total: totalCase,
            totalDot: totalDot
          });

          tCases.push(tC);
        }
      }

      tCases = tCases.sort(function(a, b) {
        return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
      });
      // console.log('categorizedCases')
      console.log(JSON.stringify(tCases, " ", 2));

      return tCases;
    },
    groupCasesByAntibiotics: function(categorizedCases) {
      var tCase = [];
      var tempData = {};
      categorizedCases.forEach(c => {
        var target = c.key;
        var cases = c.cases;
        cases.forEach(aC => {
          var k = aC.key;
          if (k === "None" || k === "Total") {
            return;
          }

          if (tempData[k] === undefined) {
            tempData[k] = {};
          }

          if (tempData[k][target] === undefined) {
            tempData[k][target] = {
              total: 0,
              totalDot: 0
            };
          }

          tempData[k][target].total = tempData[k][target].total + aC.total;
          tempData[k][target].totalDot = tempData[k][target].totalDot + aC.totalDot;
        });
      });

      for (var p1 in tempData) {
        var d1 = tempData[p1];
        var total = 0;
        var totalDot = 0;

        for (var p2 in d1) {
          var d2 = d1[p2];
          d2.key = p2;
          total += d2.total;
          totalDot += d2.totalDot;
        }

        var key = p1;
        var cases = [];
        for (p2 in d1) {
          d2 = d1[p2];
          d2.dot = ((d2.totalDot / totalDot) * 100).toFixed(2);
          cases.push(d2);
        }

        cases = cases.sort(function(a, b) {
          return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        });

        cases.push({
          key: "Total",
          total: total,
          totalDot: totalDot
        });

        console.log(total);
        tCase.push({ key: key, cases: cases });
      }

      // console.log(JSON.stringify(tCase, ' ', 2))
      // console.log(JSON.stringify(tempData, ' ', 2))
      tCase = tCase.sort(function(a, b) {
        return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
      });

      return tCase;
    },
    isInfectinSiteSupprted: function(infectionSiteString) {
      var supported = {
        GI: true,
        UTI: true,
        AUTI: true,
        Respiratory: true,
        Screening: true,
        "Skin and Soft Tissue": true
      };
      return supported[infectionSiteString] || false;
    },
    reloadPage: function(callback) {
      var vm = this;
      vm.reportDisplayData = [];
      // console.log(JSON.stringify(vm.hhSettings, ' ', 2))
      vm.$http.get(window.CONFIG.api + "/symptomDetail/unit/0/list?" + this.buildParam()).then(
        response => {
          var cases = response.body;
          var categorizedCases = vm.groupCasesByInfectionSite(cases);
          // hard coded
          vm.availableItemTypes = ["GI", "UTI", "AUTI", "Respiratory", "Screening", "Skin and Soft Tissue"];

          if (vm.infectionTypeFilter.length > 0) {
            var filteredItem = {};
            vm.infectionTypeFilter.forEach(i => {
              filteredItem[i] = true;
            });

            categorizedCases = categorizedCases.filter(v => {
              return filteredItem[v.key];
            });
          }

          var antibioticCases = vm.groupCasesByAntibiotics(categorizedCases);
          vm.availableAntibioticItemTypes = [];
          antibioticCases.forEach(function(c) {
            vm.availableAntibioticItemTypes.push(c.key);
          });

          var d3cases = [];

          var validCase = function(c) {
            if (vm.treatedFilter === 1 && c.treatmentDetails.length <= 0) {
              return false;
            } else if (vm.treatedFilter === 2 && c.treatmentDetails.length > 0) {
              return false;
            }

            if (vm.selectedUnit !== 0) {
              if (vm.selectedUnit.id !== c.unitId) {
                return false;
              }
            }

            console.log("valid case");
            console.log(c);
            return true;
          };
          if (vm.displayType === "antibiotic") {
            categorizedCases = antibioticCases;
            vm.cases.forEach(c => {
              if (!validCase(c)) {
                return;
              }
              // {"infectionSite":"UTI","onSetDate":"2018-01-29","count":1,"unitId":1}
              c.treatmentDetails.forEach(t => {
                console.log(t);
                var n = {};
                n.infectionSite = t.treatment.displayText;
                n.onSetDate = moment(c.onSetDate).format("YYYY-MM-DD");
                n.count = 1;
                n.unitId = c.unitId;
                d3cases.push(n);
              });
            });
          } else {
            vm.cases.forEach(c => {
              if (!validCase(c)) {
                return;
              }

              // {"infectionSite":"UTI","onSetDate":"2018-01-29","count":1,"unitId":1}
              var n = {};
              n.infectionSite = c.infectionSite;
              n.onSetDate = moment(c.onSetDate).format("YYYY-MM-DD");
              n.count = 1;
              n.unitId = c.unitId;
              d3cases.push(n);
            });
          }

          vm.d3Data = d3cases;
          console.log(JSON.stringify(vm.d3Data));

          // infectionTypeFilter
          // antibioticTypeFilter

          vm.categorizedCases = categorizedCases;

          if (callback) {
            vm.$nextTick(function() {
              callback();
            });
          }
        },
        response => {
          // error callback
          if (callback) {
            callback();
          }
        }
      );

      console.log(this.itemTypeFilter);
      this.loadCases();
    },
    customTypeStatus: function(o, customType) {
      // console.log(JSON.stringify(o, ' ', 2))
      // console.log(customType)
      var result = false;
      o.customTypes.forEach(function(oc) {
        if (oc.type === customType) {
          result = oc.checked;
          // if (result) {
          //   console.log(customType + ': true')
          // }
        }
      });
      return result;
    },
    sortArray: function(arrayValue) {
      return arrayValue.sort(function(a, b) {
        return a.displayText.toLowerCase().localeCompare(b.displayText.toLowerCase());
      });
    },
    update: function() {
      var vm = this;
      this.$refs["observerForm"].validate(valid => {
        if (valid) {
          var loadingInstance = Loading.service({ fullscreen: true });
          if (vm.copiedObservation.outcome.outcome === "Missed") {
            vm.copiedObservation.outcome.seconds = 0;
          }

          vm.copiedObservation.unitId = vm.copiedObservation.unit.id;
          vm.copiedObservation.unitName = vm.copiedObservation.unit.displayText;
          delete vm.copiedObservation.unit;
          var jsonData = JSON.stringify(vm.copiedObservation, " ", 2);
          // console.log('valid: ' + jsonData)
          vm.$http.put(window.CONFIG.api + "/handhygiene/observations/" + vm.copiedObservation.id, jsonData).then(
            response => {
              vm.reloadPage(function() {
                loadingInstance.close();
              });
            },
            response => {
              loadingInstance.close();
            }
          );
          vm.copyDialogVisible = false;
          vm.copiedObservation = null;
          vm.isUpdate = false;
        } else {
          // console.log('not valid')
          return false;
        }
      });
    },
    updateFilter: function() {
      var vm = this;
      var v = vm.graphDisplayFilter;
      if (v === "By Infection Site") {
        vm.displayType = "infection";
      } else if (v === "By Antibiotic") {
        vm.displayType = "antibiotic";
      }
      vm.reloadPage(function() {});
    },
    createNewObservation: function() {
      var vm = this;
      this.copiedObservation = {
        facId: null,
        observerName: "",
        unitName: "",
        unitId: null,
        unit: {},
        workerType: "",
        moment: "",
        customTypes: [],
        outcome: {
          outcome: "",
          seconds: 0
        },
        collectionDate: new Date(),
        comments: ""
      };
      var customTypes = [];
      if (this.hhSettings.customTypes) {
        this.hhSettings.customTypes.forEach(function(cType) {
          var customType = {
            type: cType,
            checked: vm.customTypeStatus(vm.copiedObservation, cType)
          };
          customTypes.push(customType);
          // console.log(cType)
        });
      }
      if (this.$refs["observerForm"]) {
        this.$refs["observerForm"].resetFields();
      }
      this.copiedObservation.customTypes = customTypes;
      this.isUpdate = false;
      this.observationDialogTitle = "New Observation";
      this.copyDialogVisible = true;
    },
    create: function() {
      var vm = this;
      this.$refs["observerForm"].validate(valid => {
        if (valid) {
          var loadingInstance = Loading.service({ fullscreen: true });
          delete vm.copiedObservation.id;
          if (vm.copiedObservation.outcome.outcome === "Missed") {
            vm.copiedObservation.outcome.seconds = 0;
          }

          var collectionDate = new Date(vm.copiedObservation.collectionDate);
          vm.copiedObservation.collectionDate = collectionDate.getTime();
          vm.copiedObservation.unitId = vm.copiedObservation.unit.id;
          vm.copiedObservation.unitName = vm.copiedObservation.unit.displayText;
          delete vm.copiedObservation.unit;
          var jsonData = JSON.stringify([vm.copiedObservation]);
          vm.$http.post(window.CONFIG.api + "/handhygiene/observations", jsonData).then(
            response => {
              vm.reloadPage(function() {
                loadingInstance.close();
              });
            },
            response => {
              loadingInstance.close();
            }
          );
          vm.copyDialogVisible = false;
          vm.copiedObservation = null;
          vm.isUpdate = false;
        } else {
          return false;
        }
      });
    },
    reloadPageWithActive: function() {
      var loadingInstance = Loading.service({ fullscreen: true });
      this.reloadPage(function() {
        loadingInstance.close();
      });
    },
    unitsByFacId: function(fId) {
      var us = [];
      this.sites.forEach(function(f) {
        if (fId === f.id) {
          us = f.units;
        }
      });

      return us;
    }
  },
  created: function() {
    var vm = this;
    vm.$watch("graphPeriodFilter", function(val) {
      if (vm.graphPeriodFilter === "By Year") {
        vm.filterPeriods = "year";
      } else if (vm.graphPeriodFilter === "By Quarter") {
        vm.filterPeriods = "quarter";
      } else if (vm.graphPeriodFilter === "By Month") {
        vm.filterPeriods = "month";
      } else if (vm.graphPeriodFilter === "By Week") {
        vm.filterPeriods = "week";
      }
    });

    vm.$watch("graphDisplayFilter", function(v) {
      vm.updateFilter();
    });

    this.searchObserver = 0;
  },
  mounted: function() {
    const end = moment()
      .endOf("year")
      .toDate();
    const start = moment()
      .startOf("year")
      .toDate();
    // const start = moment().weekday(0).toDate()
    this.filterDateRange = [start, end];

    console.log(auth.userInfo());
    var vm = this;
    var loadingInstance = Loading.service({ fullscreen: true });
    this.reloadPage(function() {
      vm.organization = vm.$configStore.data.organization || [];
      vm.sites = vm.$configStore.data.sites || [];
      vm.units = vm.$configStore.data.units || [];
      if (!vm.enterprise) {
        vm.filteredSites = vm.sites;
      }
      vm.filteredUnits = vm.units;
      vm.category = vm.organization[0].category;

      if (vm.category) {
        vm.enterprise = false;
        vm.category.subCategory = vm.category.subCategory || [];
      }
      vm.dataReady = true;
      vm.updateFacilityList();
      vm.updateFilter();
      loadingInstance.close();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-picker-panel__sidebar {
  width: 120px;
}

.el-table tr.sum-row {
  font-weight: bold;
}

.number-cell .cell {
  text-align: right;
}

.multi-select-item-filter span.el-tag.el-tag--primary {
  max-width: 90%;
}

.multi-select-item-filter span.el-select__tags-text {
  overflow: hidden;
  white-space: nowrap;
  max-width: 80% !important;
  text-overflow: clip;
  display: inline-flex;
}

.multi-select-item-filter .el-select-dropdown__item > span {
  overflow: hidden;
  white-space: nowrap;
  max-width: 80% !important;
  text-overflow: clip;
  display: inline-flex;
}
</style>
<style scoped>
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: red;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: green;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 9px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0;
}

.graph-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 9px;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filter {
  width: 150px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input,
.filter-section .el-input-number,
.filter-section .el-checkbox {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section .el-checkbox {
  padding: 10px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}

.case-page-size-filter {
  float: left;
}

.el-form .filter-section-input {
  width: 100%;
}

.el-form {
  padding-right: 10%;
}

.section-header.section-header-sub > .el-row {
  background: lightgray;
}
</style>
