<template>
  <div class="labsresuult" v-loading.fullscreen.lock="loading || loadingReports">
    <div class="filter">
      <div class="section-header">
        <el-row>
          <span class="hide-button" @click="toggleSection('filter')">
            <i v-if="toggleSectionVisibility.filter" class="el-icon-arrow-up"></i>
            <i v-if="!toggleSectionVisibility.filter" class="el-icon-arrow-down"></i>
          </span>
          Lab Results
        </el-row>
      </div>
      <!-- FILTER -->
      <location-filter v-if="sites.length > 1 && toggleSectionVisibility.filter" @update:filter="filterUpdated"> </location-filter>
      <el-row class="filter-group-label" v-if="sites.length > 1">Lab Results Filter:</el-row>
      <el-row class="filter-section" v-if="toggleSectionVisibility.filter">
        <label>
          <span class="filter-title-label">{{ useCollectionDateFilter ? "Collection" : "Culture" }} Date:</span>
          <el-date-picker class="filter-section-input" label="Date Range" v-model="filterDateRange" type="daterange" placeholder="All Dates" :format="$configStore.datePickerDateFormat()" range-separator=" ~ " :picker-options="pickerOptions" :clearable="false" small v-on:change="dateRangeUpdated">
            <template slot="prepend">Http://</template>
          </el-date-picker>
          <div style="font-size: 0.8em">
            Use Collection Date?
            <el-switch v-model="useCollectionDateFilter" v-on:change="refreshOnChange" on-color="#13ce66" on-text="" off-text=""> </el-switch>
          </div>
        </label>
        <label v-if="sites.length <= 1"
          ><span class="filter-title-label">Facility</span>
          <el-select v-model="selectedSite" placeholder="Facility" class="filter-section-input" v-on:change="unitUpdated">
            <el-option label="All Facilities" :value="0"> </el-option>
            <el-option v-for="item in sites" :key="item.id" :label="item.displayText" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label v-if="sites.length <= 1"
          ><span class="filter-title-label">Unit:</span>
          <el-select v-model="selectedUnit" placeholder="Unit" class="filter-section-input" v-on:change="unitUpdated">
            <el-option label="All Units" :value="0"> </el-option>
            <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item.id">
              <span style="float: left">{{ item.displayText }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Organisms:</span>
          <el-select filterable v-model="selectedOrganisms" class="filter-section-input" placeholder="Not Selected" v-on:change="organismsUpdated">
            <el-option label="Not Selected" v-bind:value="0"> </el-option>
            <el-option v-for="item in organisms" :key="item.name" :label="item.name" :value="item.name">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Result:</span>
          <el-select v-model="selectedResult" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="All" v-bind:value="0"> </el-option>
            <el-option v-for="item in labResults" :key="item" :label="item" :value="item">
              {{ item }}
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Source:</span>
          <el-select filterable v-model="selectedSource" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="All" v-bind:value="0"> </el-option>
            <el-option v-for="item in labSourceOptions" :key="item.value" :label="item.label" :value="item.value">
              {{ item.label }}
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Procedure:</span>
          <el-select filterable v-model="selectedProcedure" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="All" v-bind:value="0"> </el-option>
            <el-option v-for="item in labProceduresOptions" :key="item.value" :label="item.label" :value="item.value">
              {{ item.label }}
            </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Reason:</span>
          <el-select v-model="selectedReason" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="All" v-bind:value="0"></el-option>
            <el-option value="Symptomatic">Symptomatic</el-option>
            <el-option value="Contact">Contact</el-option>
            <el-option value="Surveillance">Surveillance</el-option>
            <el-option value="Not Selected">Not Selected</el-option>
          </el-select>
        </label>
        <label v-if="isAdminStaff()">
          <span class="filter-title-label">Individual Type:</span>
          <el-select v-model="selectedClientType" class="filter-section-input" placeholder="Client Type" v-on:change="refreshOnChange">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="Resident" :value="1"> </el-option>
            <el-option label="Staff" :value="2"> </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">First Name:</span>
          <el-input placeholder="First Name" v-model="selectedFirstName" icon="search" :on-icon-click="refreshOnChange"></el-input>
        </label>
        <label>
          <span class="filter-title-label">Last Name:</span>
          <el-input placeholder="Last Name" v-model="selectedLastName" icon="search" :on-icon-click="refreshOnChange"></el-input>
        </label>
        <label>
          <span class="filter-title-label">Individual Status:</span>
          <el-select v-model="selectedStatus" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="All" :value="0"> </el-option>
            <el-option label="Active" value="C"> </el-option>
            <el-option label="Discharged" value="D"> </el-option>
            <el-option label="Transferred" value="T"> </el-option>
          </el-select>
        </label>
        <label>
          <span class="filter-title-label">Most Recent Labs:</span>
          <el-select v-model="mostRecentSwabs" class="filter-section-input" placeholder="Not Selected" v-on:change="refreshOnChange">
            <el-option label="Most recent only" v-bind:value="true"></el-option>
            <el-option label="All" v-bind:value="false"></el-option>
          </el-select>
        </label>
      </el-row>
    </div>
    <!-- New Case Dialog -->
    <lab-results-wizard v-if="showBatchEditWizard" v-on:wizard-closed="batchEditClosed" v-on:lab-batch-edit="applyBatchUpdates" :labResultData="{ _isBatch: true }" :mode="'edit'"> </lab-results-wizard>
    <lab-results-wizard v-if="showLabResultsWizard" v-on:wizard-closed="labWizardClosed" v-on:lab-created="closeWizardAndReload" v-on:lab-updated="closeWizardAndReload" v-on:lab-batch-complete="closeWizardAndReload" :labResultData="selectedLabResults" :mode="labResultsWizardMode"> </lab-results-wizard>

    <div class="section-header">
      <el-row>
        <span class="hide-button" @click="toggleSection('summary')">
          <i v-if="toggleSectionVisibility.summary" class="el-icon-arrow-up"></i>
          <i v-if="!toggleSectionVisibility.summary" class="el-icon-arrow-down"></i>
        </span>
        Summary
        <div class="case-filter">
          <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportSummary()">Export Data</el-button>
        </div>
      </el-row>
    </div>
    <!-- Summary Table -->
    <el-table v-if="toggleSectionVisibility.summary" :data="summaryTableData" style="width: 100%">
      <el-table-column fixed prop="title" label="Month" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.label }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Positive Results">
        <template slot-scope="scope">
          <span>{{ scope.row.positive }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Negative Results">
        <template slot-scope="scope">
          <span>{{ scope.row.negative }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Assumed Negative Results">
        <template slot-scope="scope">
          <span>{{ scope.row.assumednegative }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Indeterminate Results">
        <template slot-scope="scope">
          <span>{{ scope.row.indeterminate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Pending Results">
        <template slot-scope="scope">
          <span>{{ scope.row.pending }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Refused Results">
        <template slot-scope="scope">
          <span>{{ scope.row.refused }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tests Performed">
        <template slot-scope="scope">
          <span>{{ scope.row.performed }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Individuals Tested">
        <template slot-scope="scope">
          <span>{{ scope.row.tested }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- Lab Results Table -->
    <div class="section-header">
      <el-row>
        <span class="hide-button" @click="toggleSection('results')">
          <i v-if="toggleSectionVisibility.results" class="el-icon-arrow-up"></i>
          <i v-if="!toggleSectionVisibility.results" class="el-icon-arrow-down"></i>
        </span>
        Results - {{ symptomDetailTotal }}
        <div class="case-filter">
          <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="createLab()">+ New Lab Result</el-button>
          <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="batchLabEntry()">Batch Lab Entry</el-button>
          <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="batchLabEdit()">Batch Lab Edit</el-button>
          <el-select class="case-filter-select" size="mini" v-model="symptomDetailPageSize" placeholder="Choose tags for your article">
            <el-option v-for="item in symptomDetailPageSizeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-button class="case-export-button" :disabled="this.filterDateRange == '' || this.filterDateRange[0] == ''" :plain="true" type="primary" size="mini" @click="exportExcel()">Export Data</el-button>
          <el-pagination small layout="prev, pager, next" :current-page="symptomDetailPage" :page-count="symptomDetailPageTotal" v-on:current-change="reloadPage"> </el-pagination>
        </div>
      </el-row>
    </div>
    <el-table v-if="!loadingReports && toggleSectionVisibility.results" :data="labTableData" style="width: 100%" v-on:row-click="editLab">
      <el-table-column fixed label="" width="100">
        <template slot-scope="scope">
          <div style="padding: 0.5em">
            <div :span="4" class="profile-row-photo"><img :src="clientPhoto(scope.row.client)" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Name" fixed width="180">
        <template slot-scope="scope">
          <div>{{ scope.row.client.firstName }} {{ scope.row.client.middleName }} {{ scope.row.client.lastName }}</div>
          ({{ getIdForItem(scope.row.client) }})
        </template>
      </el-table-column>
      <el-table-column label="Result" width="125">
        <template slot-scope="scope">
          {{ scope.row.result }}
        </template>
      </el-table-column>
      <el-table-column label="Organism" width="140">
        <template slot-scope="scope">
          {{ scope.row.organism }}
        </template>
      </el-table-column>

      <el-table-column label="Linked" width="70">
        <template slot-scope="scope">
          {{ scope.row.caseId ? "Yes" : "No" }}
        </template>
      </el-table-column>
      <el-table-column label="Procedure" width="160">
        <template slot-scope="scope">
          {{ scope.row.procedure }}
        </template>
      </el-table-column>
      <el-table-column label="Source" width="130">
        <template slot-scope="scope">
          {{ scope.row.source }}
        </template>
      </el-table-column>
      <el-table-column label="Reason" width="125">
        <template slot-scope="scope">
          {{ scope.row.reason }}
        </template>
      </el-table-column>
      <el-table-column label="Facility" width="180">
        <template slot-scope="scope">
          {{ isStaff(scope.row.client) ? getFacilityNamesForStaff(scope.row.client.facilities).join(", ") : getFacilityNameForResident(scope.row.client.facId) }}
        </template>
      </el-table-column>
      <el-table-column label="Location" width="150">
        <template slot-scope="scope">
          {{ isStaff(scope.row.client) ? "" : beds[scope.row.client.bedId] ? beds[scope.row.client.bedId].fullDesc : unitById(scope.row.client.unitId) }}
        </template>
      </el-table-column>
      <el-table-column label="Lab ID" width="180">
        <template slot-scope="scope">
          {{ scope.row.labId }}
        </template>
      </el-table-column>
      <el-table-column label="Collection Date" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.collectionDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Culture Date" fixed="right" width="100">
        <template slot-scope="scope" v-if="scope.row.cultureDate">
          {{ scope.row.cultureDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="" prop="active" width="120">
        <template slot-scope="scope">
          <el-button :disabled="!canModify" @click="addOrganism(scope.$index, scope.row, $event)" size="small">
            <Icon :iconKey="'addCircle'" :description="'Add Organism'" />
          </el-button>
          <el-button :disabled="!canModify" @click="deleteCase($event, scope.row)" size="small">
            <Icon :iconKey="'delete'" :description="'Delete'" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Batch Lab ID picker -->
    <el-dialog title="Select Lab ID" v-model="selectLabIdVisible" v-if="selectLabIdVisible" class="batch-edit" :close-on-click-modal="false" :close-on-press-escape="false">
      <template v-if="batchEditStage === 'filter'">
        <el-form ref="batchFilterForm" :model="batchFilters">
          <el-form-item>
            <el-select style="width: 400px" placeholder="Search for Lab Id" filterable clearable remote :remote-method="getAvailableBatchIds" :loading="selectBatchIdIsLoading" v-model="batchFilters.labId">
              <el-option v-for="item in availableBatchIds" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </el-form-item>

          <template v-if="batchFilters.labId">
            <el-form-item label="Result:" prop="result">
              <el-select v-model="batchFilters.result" clearable placeholder="Any">
                <el-option value="Positive"></el-option>
                <el-option value="Indeterminate"></el-option>
                <el-option value="Negative"></el-option>
                <el-option value="Assumed Negative"></el-option>
                <el-option value="Pending"></el-option>
                <el-option value="Refused"></el-option>
                <el-option value="Test Not Performed"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Organism:" prop="organism">
              <el-select v-model="batchFilters.organism" value-key="name" clearable filterable placeholder="Any">
                <el-option v-for="item in organisms" :key="item.name" :label="item.name" :value="item.name">
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Procedure:" prop="procedure">
              <el-select v-model="batchFilters.procedure" clearable placeholder="Any">
                <el-option v-for="option in labProceduresOptions" :key="option.value" :value="option.value">{{ option.label }}</el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Source:" prop="source">
              <el-select v-model="batchFilters.source" clearable placeholder="Any">
                <el-option v-for="option in labSourceOptions" :key="option.value" :value="option.value">{{ option.label }}</el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Reason:" prop="reason">
              <el-select v-model="batchFilters.reason" clearable placeholder="Any">
                <el-option value="Symptomatic">Symptomatic</el-option>
                <el-option value="Contact">Contact</el-option>
                <el-option value="Surveillance">Surveillance</el-option>
                <el-option value="Not Selected">Not Selected</el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template v-if="batchEditStage === 'previewResults'">
        {{ batchQueryCount }} result(s) found.
        <p v-if="batchQueryCount > 10"><strong>Below is a preview of the first 10:</strong></p>
        <table class="batchRsultsPreview">
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
              <th>Facility</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lab in batchQueryPreview" :key="lab.id">
              <td>{{ lab.client.firstName }} {{ lab.client.middleName }} {{ lab.client.lastName }}</td>
              <td>{{ lab.client.id }}</td>
              <td>{{ isStaff(lab.client) ? getFacilityNamesForStaff(lab.client.facilities).join(", ") : getFacilityNameForResident(lab.client.facId) }}</td>
            </tr>
          </tbody>
        </table>
        <p>Would you like to proceed?</p>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="batchEditStage === 'filter'" @click="selectLabIdVisible = false">Cancel</el-button>
        <el-button v-if="batchEditStage === 'previewResults'" @click="batchEditStage = 'filter'">Go Back</el-button>
        <el-button
          v-if="batchEditStage === 'filter'"
          type="primary"
          :disabled="!batchFilters.labId"
          @click="
            batchEditStage = 'previewResults';
            runBatchQuery(batchFilters);
          "
          >Query</el-button
        >
        <el-button v-if="batchEditStage === 'previewResults'" type="primary" @click="confirmBatchQuery()">Continue</el-button>
      </span>
    </el-dialog>

    <progress-overlay v-if="excelExportResultsVisible" :progress="excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>
  </div>
</template>

<script>
import auth from "../auth";
// import api from '../services/restClient'

import Icon from "./Shared/Icon";
import moment from "moment";
import { Loading } from "element-ui";
import SurveillanceCaseDetail from "../components/SurveillanceCaseDetail";
import SurveillanceLocation from "../components/SurveillanceLocation";
import SurveillanceCommunication from "../components/SurveillanceCommunication";
import SurveillanceBiology from "../components/SurveillanceBiology";
import SurveillanceDevice from "../components/SurveillanceDevice";
import SurveillanceSymptoms from "../components/SurveillanceSymptoms";
import SurveillanceTreatment from "../components/SurveillanceTreatment";
import SurveillancePersonalInfo from "../components/SurveillancePersonalInfo";
import SurveillancePrecaution from "../components/SurveillancePrecaution";
import LocationFilter from "../components/LocationFilter/LocationFilter";
import InfectionCaseDetail from "../components/InfectionCaseDetail";
import LabResultsWizard from "../components/LabResultsWizard";
import ProgressOverlay from "../components/ProgressBarOverlay";
import XLSX from "xlsx";

export default {
  name: "lab-results",
  components: {
    "surveillance-case-detail": SurveillanceCaseDetail,
    "surveillance-communication": SurveillanceCommunication,
    "surveillance-biology": SurveillanceBiology,
    "surveillance-device": SurveillanceDevice,
    "surveillance-symptoms": SurveillanceSymptoms,
    "surveillance-treatment": SurveillanceTreatment,
    "surveillance-personal-info": SurveillancePersonalInfo,
    "surveillance-precaution": SurveillancePrecaution,
    "surveillance-location": SurveillanceLocation,
    "infection-case-detail": InfectionCaseDetail,
    "location-filter": LocationFilter,
    "lab-results-wizard": LabResultsWizard,
    "progress-overlay": ProgressOverlay,
    Icon,
  },
  data: function () {
    return {
      labResults: [],
      cancelExcelExportProgress: false,
      excelExportResultsVisible: false,
      excelExportResultsProgress: 0,
      useCollectionDateFilter: false,
      showBatchEditWizard: false,
      batchQueryCount: 0,
      batchQueryPreview: [],
      batchEditStage: "filter",
      batchFilters: {},
      availableBatchIds: [],
      selectBatchIdIsLoading: false,
      selectLabIdVisible: false,
      showLabResultsWizard: false,
      selectedLabResults: null,
      loadingReports: false,
      newCaseFac: null,
      newClientSelection: null,
      canModify: false,
      fixClick: false,
      filterSelectionVisible: false,
      loading: true,
      firstLoad: false,
      currentDate: new Date(),
      cases: [],
      locations: [],
      precautions: [],
      beds: {},
      bedMap: {},
      symptomTypes: [],
      symptoms: [],
      searchNameInput: "",
      clients: {},
      clientList: [],
      clientService: {},
      symptomTypeMap: {},
      customSymptomTypeMap: {},
      sortedBy: "cultureDate",
      mostRecentSwabs: false,
      origins: [],
      outcomes: [
        {
          value: "Chronic",
          label: "Chronic",
        },
        {
          value: "Deceased",
          label: "Deceased",
        },
        {
          value: "Discharged",
          label: "Discharged",
        },
        {
          value: "Hospitalized",
          label: "Hospitalized",
        },
        {
          value: "Resolved",
          label: "Resolved",
        },
      ],
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4",
      },
      statusMap: {
        C: "Active",
        D: "Discharged",
        T: "Transferred",
      },
      allFilters: {
        dateRange: true,
        unit: true,
        infSite: true,
        status: true,
        origin: true,
        treatment: false,
        precaution: false,
        organism: false,
        // resolution: false,
        familyNotified: false,
        nextOfKinNotified: false,
        physicianNotified: false,
        staffOrRes: false,
        // ,
        // firstName: false,
        // lastName: false
      },
      organismOptionsSelection: [0],
      caseActiveTab: "1",
      selectedClientStatus: 0,
      selectedCase: null,
      selectedClient: null,
      selectedUnit: 0,
      selectedOrigin: 0,
      selectedStatus: 0,
      selectedTreatment: 0,
      selectedPrecautions: 0,
      selectedOrganisms: 0,
      selectedResolved: 0,
      selectedResult: 0,
      selectedSource: 0,
      selectedProcedure: 0,
      selectedReason: 0,
      selectedFamilyNotified: 0,
      selectedNextOfNotified: 0,
      selectedPhysicianNotified: 0,
      selectedClientType: 0,
      selectedSite: 0,
      selectedFirstName: "",
      selectedLastName: "",
      selectedCaseToDelete: null,
      dialogFormVisible: false,
      newCaseDialogFormVisible: false,
      newLabResultFormVisible: false,
      includeDischargedClient: false,
      dialogConfirmDeleteVisible: false,
      newCase: {
        client: null,
        symptoms: [],
        error: [],
        subtypeString: "",
        newCaseFac: null,
      },
      subStatusMap: {
        GI: ["Noro Virus", "CDI", "GE"],
        UTI: ["ABUTI", "SUTI", "ASB"],
        Respiratory: ["Upper/Common Cold", "Lower/Pnem/Bron", "Flu - Influezna Like", "SINU"],
      },
      symptomDetailPage: 1,
      symptomDetailPageSize: 5,
      symptomDetailPageTotal: 0,
      symptomDetailTotal: 0,
      symptomDetailPageSizeOptions: [
        {
          label: "5 items per page",
          value: 5,
        },
        {
          label: "10 items per page",
          value: 10,
        },
        {
          label: "20 items per page",
          value: 20,
        },
      ],
      filterDateRange: "",
      filteredSite: {},
      labReports: {},
      filterPeriods: "week",
      filterSymptomTypes: [],
      filteredSiteSubType: 0,
      organismOptions: [],
      availableUnits: [],
      organisms: [],
      treatments: [],
      units: [],
      sites: [],
      organization: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "Last 7 Days",
            onClick(picker) {
              const start = moment().subtract(6, "days").toDate();
              const end = moment().toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 30 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(30, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 90 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(90, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = moment().weekday(-1).toDate();
              const start = moment().weekday(-7).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const lastMonth = moment().subtract(1, "months");
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const lastYear = moment().subtract(1, "years");
              const end = lastYear.endOf("year").toDate();
              const start = lastYear.startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current week",
            onClick(picker) {
              const end = moment().weekday(6).toDate();
              const start = moment().weekday(0).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current month",
            onClick(picker) {
              const lastMonth = moment();
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current year",
            onClick(picker) {
              const end = moment().endOf("year").toDate();
              const start = moment().startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      toggleSectionVisibility: {
        summary: true,
        filter: true,
        results: true,
      },
      labProceduresOptions: [],
      labSourceOptions: [],
      summaryTableData: [],
      labTableData: [],
    };
  },
  computed: {
    symptomTypesWIthoutCommon: function () {
      return (this.symptomTypes || []).filter((s) => s.type !== "Common");
    },
    clientsForSelectedFac: function () {
      let vm = this;
      return (this.clientList || []).filter((c) => {
        return c.status !== "D" || vm.includeDischargedClient;
      });
    },
  },
  watch: {
    organismOptions: function (v) {
      console.log("organismOptions: ", v);
    },
    symptoms: function (v) {
      console.log(v);
      v.forEach((s) => {
        console.log(s.classType, s.displayText);
      });
    },
    newCaseFac: function (v) {
      if (v === null) {
        this.clientList = [];
      } else {
        // start loading
        this.newCaseFacSelection();
      }
    },
    "newCase.newCaseFac": function (v) {
      if (v === null) {
        this.clientList = [];
      } else {
        // start loading
        this.newCaseFacSelection();
      }
    },
    filterDateRange: function (v) {
      console.log("filterDateRange", v);
    },
  },
  methods: {
    isAdminStaff: function () {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    newCaseFacSelection: async function () {
      console.log("newCaseFacSelection");
      if (!this.newCase.newCaseFac) {
        return;
      }

      let loadingInstance = Loading.service({ fullscreen: true });
      let vm = this;

      let currentPage = 0;
      let url = `${window.CONFIG.api}/clients/list/unit/0/page/${currentPage}?&sort=firstName&size=100&clientType=${this.newCase.clientType}`;
      url += this.sites.length > 1 ? `&facIds=${this.newCase.newCaseFac.id}` : "";

      this.$http
        .get(url)
        .then((response) => {
          console.log(response.body);
          let clients = [];
          if (response.body) {
            clients = response.body.content; // body['_embedded'].clients
          }
          vm.clientList = clients;
        })
        .finally(() => loadingInstance.close());

      let fullList = [];
      if ((vm.clientList || []).length === 100) {
        let result = [...vm.clientList];
        while (result && result.length === 100) {
          let response = await this.$http.get(`${window.CONFIG.api}/clients/list/unit/0/page/${++currentPage}?&sort=firstName&size=100&clientType=${this.newCase.clientType}`);
          if (response && response.body) {
            result = response.body.content;
          }
          fullList = [...fullList, ...result];
        }

        vm.clientList = fullList;
      }
    },
    nameParser: function (c) {
      let vm = this;
      if (c.status !== "D" || vm.includeDischargedClient) {
        let value = c.firstName + (c.middleName ? " " + c.middleName + " " : " ") + c.lastName;
        value = value + "(" + (this.$configStore.patientIdType() ? c[this.$configStore.patientIdType()] : c.patientId) + ")";
        if (c.status === "D") {
          value = value + "- (Discharged)";
        }

        return value;
      }

      return null;
    },
    newClientSelected: function (selection) {
      console.log(this.newClientSelection);
      this.newCase.client = selection.item;
    },
    reloadCases: function () {
      this.dialogFormVisible = false;
      this.selectedCase = null;
      this.loadCases();
    },
    filterUpdated: function (o) {
      this.selectedUnit = o.unit;
      this.selectedSite = o.site;
      this.availableUnits = o.units || [];
      this.availableFacs = o.sites ? o.sites : 0;
      this.loadCases();
      this.loadSummary();
    },
    toggleSection: function (section) {
      this.toggleSectionVisibility[section] = !this.toggleSectionVisibility[section];
    },
    filterPrecautions: function (precautions) {
      let vm = this;
      return (precautions || []).filter((p) => vm.precautionById(p.precautionId));
    },
    validSymptoms: function (newCase) {
      return (this.symptoms || []).filter((s) => {
        return newCase.symptomType && s.classType === newCase.symptomType.type;
      });
    },
    updatePersonalNotes(newNote) {
      console.log(newNote);
      if (this.clients[this.selectedClient.clientId]) {
        var clientData = this.clients[this.selectedClient.clientId];
        clientData.notes.splice(0, 0, newNote);
      }
    },
    handleChange(value) {
      console.log(value);
      this.filteredSite = value[0];
      this.filteredSiteSubType = value[1] || 0;

      this.symptomTypeUpdated(value[0]);
    },
    getsubStatusMap: function (caseData) {
      // console.log(caseData)
      if (caseData && caseData.symptomType && caseData.symptomType.subTypes) {
        // console.log(this.subStatusMap[caseData.symptomType.text])
        // console.log('getsubStatusMap subtypes')
        return caseData.symptomType.subTypes;
      }

      // console.log('getsubStatusMap')
      return [];
    },
    buildParam: function () {
      var rUrl = "";

      if (this.filterDateRange !== "" && this.filterDateRange[0] !== "") {
        console.log("this.filterDateRange", this.filterDateRange);
        var after = this.filterDateRange[0] || new Date();
        var before = this.filterDateRange[1] || new Date();

        after = moment(after).hours(0).minutes(0).seconds(0).toDate();
        before = moment(before).hours(23).minutes(59).seconds(59).toDate();

        rUrl = "start=" + encodeURIComponent(after) + "&end=" + encodeURIComponent(before);
      }
      rUrl = rUrl + "&types=" + this.filterSymptomTypes;

      if (this.filteredSiteSubType !== 0) {
        rUrl = rUrl + "&subType=" + this.filteredSiteSubType;
      }

      if (this.selectedOrigin !== 0) {
        rUrl = rUrl + "&origin=" + this.selectedOrigin;
      }

      if (this.selectedStatus !== 0) {
        rUrl = rUrl + "&clientStatus=" + this.selectedStatus;
      }

      if (this.selectedPrecautions !== 0) {
        rUrl = rUrl + "&precaution=" + this.selectedPrecautions;
      }

      if (this.selectedOrganisms !== 0) {
        rUrl = rUrl + "&organism=" + this.selectedOrganisms;
      }

      if (this.selectedResolved !== 0) {
        if (this.selectedResolved === "Resolved" || this.selectedResolved === "Not Resolved") {
          rUrl = rUrl + "&resolved=" + (this.selectedResolved === "Resolved" ? "true" : "false");
        } else {
          rUrl = rUrl + "&outcome=" + this.selectedResolved;
        }
      }

      if (this.selectedFamilyNotified !== 0) {
        rUrl = rUrl + "&familyNotified=" + (this.selectedFamilyNotified === 1 ? "true" : "false");
      }

      if (this.selectedNextOfNotified !== 0) {
        rUrl = rUrl + "&nextOfNotified=" + (this.selectedNextOfNotified === 1 ? "true" : "false");
      }

      if (this.selectedPhysicianNotified !== 0) {
        rUrl = rUrl + "&physicianNotified=" + (this.selectedPhysicianNotified === 1 ? "true" : "false");
      }

      if (this.selectedClientType !== 0) {
        rUrl = rUrl + "&clientType=" + (this.selectedClientType === 1 ? "Client" : "Staff");
      }

      if (this.selectedFirstName !== "") {
        rUrl = rUrl + "&firstName=" + this.selectedFirstName;
      }

      if (this.selectedLastName !== "") {
        rUrl = rUrl + "&lastName=" + this.selectedLastName;
      }

      if (this.selectedTreatment !== 0) {
        rUrl = rUrl + "&treatmentId=" + this.selectedTreatment;
      }

      if (this.selectedClientStatus !== 0) {
        rUrl = rUrl + "&clientActive=" + (this.selectedClientStatus === 1 ? "true" : "false");
      }

      if (Array.isArray(this.availableUnits) && this.availableUnits.length > 0) {
        rUrl = rUrl + "&units=" + this.availableUnits;
      }

      if (this.selectedSite > 0) {
        rUrl = rUrl + "&facIds=" + [this.selectedSite];
      } else if (this.availableFacs !== 0 && Array.isArray(this.availableFacs)) {
        rUrl = rUrl + "&facIds=" + this.availableFacs;
      }

      rUrl = rUrl + "&sort=" + this.sortedBy;

      console.log(rUrl);
      return rUrl;
    },
    getFiltersAsQueryString: function () {
      const vm = this;
      const filters = {
        sort: vm.sortedBy,
        site: vm.selectedSite,
        units: vm.selectedUnit,
        organism: vm.selectedOrganisms,
        result: vm.selectedResult,
        source: vm.selectedSource,
        procedure: vm.selectedProcedure,
        reason: vm.selectedReason,
        clientType: vm.selectedClientType,
        clientStatus: vm.selectedStatus,
        firstName: vm.selectedFirstName,
        lastName: vm.selectedLastName,
        recent: vm.mostRecentSwabs,
      };

      if (this.selectedSite > 0) {
        filters.facIds = [this.selectedSite];
      } else if (this.availableFacs !== 0 && Array.isArray(this.availableFacs)) {
        filters.facIds = this.availableFacs;
      }

      const start = moment(vm.filterDateRange[0]).startOf("day").toDate();
      const end = moment(vm.filterDateRange[1]).endOf("day").toDate();

      if (this.useCollectionDateFilter) {
        filters.collectionStart = start;
        filters.collectionEnd = end;
      } else {
        filters.start = encodeURIComponent(start);
        filters.end = encodeURIComponent(end);
      }

      filters.clientType = filters.clientType === 0 ? null : filters.clientType === 1 ? "Client" : "Staff";
      return Object.entries(filters)
        .filter((entry) => entry[1])
        .map((entry) => `${entry[0]}=${entry[1]}`)
        .join("&");
    },
    loadCases: async function () {
      console.log("loadCases", this.filterDateRange);
      if (!this.loading) {
        this.loading = true;
      }
      const vm = this;
      // const uniqueClientIds = {}
      // const clientMap = {}
      const parameters = this.getFiltersAsQueryString();
      const response = await this.$http.get(`${window.CONFIG.api}/labs?page=${vm.symptomDetailPage - 1}&size=${vm.symptomDetailPageSize}&${parameters}`);
      vm.symptomDetailTotal = response.body.totalElements;
      vm.symptomDetailPage = response.body.number + 1;
      vm.symptomDetailPageTotal = response.body.totalPages;

      vm.labTableData = response.body.content.filter((lab) => lab.clientId !== 0);
      // await Promise.all(
      //   baseLabs.filter(lab => {
      //     if (!uniqueClientIds[lab.clientId]) {
      //       uniqueClientIds[lab.clientId] = true
      //       return true
      //     } else {
      //       return false
      //     }
      //   })
      //   .map(lab => this.$http.get(`${window.CONFIG.api}/clients/${lab.clientId}`).then(response => {
      //     clientMap[response.body.id] = response.body
      //     return response.body
      //   }))
      // )
      // vm.labTableData = baseLabs.map(lab => {
      //   lab.client = clientMap[lab.clientId]
      //   return lab
      // })
      vm.$nextTick(function () {
        vm.firstLoad = true;
        vm.loading = false;
      });
    },
    clientName: function (symptomDetail) {
      var clientData = symptomDetail.client;
      this.clients[symptomDetail.clientId] = clientData;
      return this.getClientName(clientData);
    },
    getClientName: function (clientData) {
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      // console.log(JSON.stringify(clientData, ' ', 2))
      return firstName + middleName + lastName;
    },
    sortedSymptom(symptoms) {
      // <el-tag v-for="d in scope.row.symptoms
      if (!symptoms || symptoms.length <= 1) {
        return symptoms;
      }

      // console.log(JSON.stringify(symptoms, ' ', 2))
      // classType: "GI"
      // colorCode: "0"
      // displayText: "Diarrhea"
      let sortedSymptoms = [];
      symptoms.forEach((s) => {
        sortedSymptoms.push(s);
      });
      sortedSymptoms.sort(function (a, b) {
        let compA = a.classType || "";
        let compB = b.classType || "";

        if (compA === "GI") compA = "a" + a.displayText;
        else if (compA === "Respiratory") compA = "b" + a.displayText;
        else if (compA === "UTI") compA = "c" + a.displayText;
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a" + b.displayText;
        else if (compB === "Respiratory") compB = "b" + b.displayText;
        else if (compB === "UTI") compB = "c" + b.displayText;
        else compB = "d" + b.displayText;

        let x = compA.toLowerCase();
        let y = compB.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      return sortedSymptoms;
    },
    nameFilter: function (cases) {
      var vm = this;
      return cases.filter(function (detail) {
        return detail.name.toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    reloadPage: function (page) {
      if (this.symptomDetailPage !== page) {
        this.symptomDetailPage = page;
        if (this.firstLoad) {
          this.loadCases();
        }
      }
    },
    loadImage: function (detail, callback) {
      var vm = this;
      if (this.clients[detail.clientId] || this.clients[detail.clientId] === false) {
        callback();
        return;
      }
      this.clients[detail.clientId] = false;
      this.$http.get(window.CONFIG.api + "/clients/" + detail.clientId).then(
        (response) => {
          // console.log(JSON.stringify(response.body, ' ', 2))
          vm.$set(vm.clients, detail.clientId, response.body);
          vm.$forceUpdate();
          callback();
        },
        (response) => {
          // error
          callback();
        }
      );
    },
    statusImage: function (detail) {
      var result = "";
      var filename;
      if (detail.symptomType) {
        filename = this.statusImageMap[detail.symptomType];
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      if (result === "") {
        filename = "bug4";
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      return result;
    },
    tagType: function (type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    dateRangeUpdated: function (value) {
      console.log("dateRangeUpdated", value);
      if (value === "") {
        console.log("date range empty");
        this.filterDateRange = ["", ""];
      } else if (!value) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

        this.filterDateRange = [start, end];
      }

      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    unitUpdated: function (value) {
      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    originUpdated: function (value) {
      if (value === 0) {
        this.filterOrigin = null;
      } else {
        this.filterOrigin = value;
      }
      // console.log('originUpdated:' + value)
      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    precautionUpdated: function (value) {
      // console.log('precautionUpdated: ', +value)
      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    organismsUpdated: function (value) {
      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    refreshOnChange: function (value) {
      if (this.firstLoad) {
        this.loadCases();
        this.loadSummary();
      }
    },
    confirmStatusUpdated: function (value) {
      // console.log('confirmStatusUpdated:' + value)
      if (value === 0) {
        this.filterConfirmStatus = null;
      } else {
        this.filterConfirmStatus = value === 1 ? "true" : "false";
      }

      if (this.firstLoad) {
        this.loadCases();
      }
    },
    recentSwabUpdated: function () {
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    organismById: function (organId) {
      var organName = "Not Selected";
      if (organId === 0) {
        return organName;
      }

      this.organisms.forEach(function (o) {
        if (o.id === organId) {
          organName = o.name;
        }
      });

      return organName;
    },
    treatmentById: function (treatmentId) {
      var name = "Not Selected";
      if (treatmentId === 0) {
        return name;
      }

      this.treatments.forEach(function (t) {
        if (t.id === treatmentId) {
          name = t.displayText;
        }
      });

      return name;
    },
    getTreatments: function (caseData) {
      let result = "";
      caseData.treatmentDetails.forEach((d) => {
        // if (d.endDate) {
        //   return
        // }

        if (result.length > 0) {
          result = result + ", ";
        }
        if (d.customTreatment) {
          result = result + d.customTreatment;
        } else if (d.treatment && d.treatment.displayText) {
          result = result + d.treatment.displayText;
        }
      });

      if (result === "") {
        result = "Not Selected";
      }

      return result;
    },
    labResult: function (caseData) {
      let result = "Not Selected";
      let reports = this.labReports[caseData.id] || [];
      reports.forEach((l) => {
        if (l.organism === caseData.primaryOrganism) {
          result = l.result || "Not Selected";
        }
      });

      return result;
    },
    unitById: function (unitId) {
      var unitName = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    facilityNameByUnitId: function (unitId) {
      var name = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          name = u.site.displayText;
        }
      });

      return name;
    },
    getFacilityNameForResident(id) {
      return this.sites.filter((s) => s.id === id)[0].displayText;
    },
    getFacilityNamesForStaff(ids) {
      return this.sites.filter((s) => ids.includes(s.id)).map((s) => s.displayText);
    },
    originById: function (originId) {
      var originName = "Not Selected";
      this.origins.forEach(function (o) {
        if (o.value === originId && o.value !== 0) {
          originName = o.label;
        }
      });
      return originName;
    },
    unitByName: function (unitName) {
      var unit = null;
      this.units.forEach(function (u) {
        if (u.displayText === unitName) {
          unit = u;
        }
      });

      return unit;
    },
    precautionById: function (precautionId) {
      var name = "";
      this.precautions.forEach(function (p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    clientPhoto: function (client) {
      if (client && client.photo) {
        var f = client.photo;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "/static/" + (client.photo ? client.photo : "user") + ".png";
      }

      return "/static/user.png";
    },
    canSave: function (symptomDetail) {
      var canSave = false;
      symptomDetail.symptoms.forEach(function (s) {
        if (symptomDetail.symptomType === s.classType) {
          canSave = true;
        } else if (symptomDetail.infectionSite === s.classType) {
          canSave = true;
        }

        // console.log('- canSave ' + symptomDetail.symptomType + '- ' + s.classType)
        // console.log(symptomDetail)
        // console.log(s)
        // console.log('symptomDetail.infectionSite: ' + symptomDetail.infectionSite)
      });

      // console.log('canSave ' + (canSave ? 'Yes' : 'NO'))

      return canSave;
    },
    reverse: function (array) {
      return array.slice().reverse();
    },
    isStaff: function (client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    createExcelFile: function (fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    exportSummary: function () {
      var columns = ["Month", "Positive Results", "Negative Results", "Assumed Negative Results", "Indeterminate Results", "Pending Results", "Tests Performed", "Individuals Tested"];
      const data = this.summaryTableData.map((row) => {
        return [row.label, row.positive, row.negative, row.assumednegative, row.indeterminate, row.pending, row.performed, row.tested];
      });
      this.createExcelFile("lab_summary.xlsx", columns, data);
    },
    showLabResultsForm(row) {
      this.selectedLabResults = JSON.parse(JSON.stringify(row));
      this.showLabResultsWizard = true;
    },
    createOrgamism(row) {
      this.labResultsWizardMode = "addOrganism";
      this.showLabResultsForm(row);
    },
    createLab() {
      this.labResultsWizardMode = "new";
      this.showLabResultsForm({});
    },
    editLab(row) {
      this.labResultsWizardMode = "edit";
      this.showLabResultsForm(row);
    },
    closeWizardAndReload(lab) {
      this.reloadCases();
      this.labWizardClosed();
    },
    labWizardClosed() {
      this.showLabResultsWizard = false;
    },
    addOrganism: function (index, row, event) {
      event.stopPropagation();
      this.createOrgamism(row);
    },
    batchLabEdit() {
      this.selectLabIdVisible = true;
      this.batchEditStage = "filter";
      this.batchFilters = {};
      this.availableBatchIds = [];
      this.selectBatchIdIsLoading = false;
      this.showBatchEditWizard = false;
    },
    getAvailableBatchIds(query) {
      this.$http.get(`${window.CONFIG.api}/labs/batch/ids?startsWith=${query}`).then(
        (response) => {
          this.availableBatchIds = response.body;
        },
        (error) => {
          throw Error(error);
        }
      );
    },
    async runBatchQuery(filters) {
      const clientMap = {};
      const queryParams = Object.entries(filters)
        .map((pair) => pair.join("="))
        .join("&");
      const response = await this.$http.get(`${window.CONFIG.api}/labs?${queryParams}`);
      this.batchQueryCount = response.body.totalElements;
      await Promise.all(
        response.body.content.map((lab) =>
          this.$http.get(`${window.CONFIG.api}/clients/${lab.clientId}`).then((response) => {
            clientMap[response.body.id] = response.body;
          })
        )
      );
      this.batchQueryPreview = response.body.content.map((lab) => {
        lab.client = clientMap[lab.clientId];
        return lab;
      });
    },
    confirmBatchQuery() {
      this.showBatchEditWizard = true;
    },
    applyBatchUpdates(rawWizardData) {
      const batchUpdates = {
        labId: this.batchFilters.labId,
      };
      Object.entries(this.batchFilters)
        .filter((pair) => pair[0] !== "labId" && pair[1])
        .forEach((keyValue) => {
          const [k, v] = keyValue;
          const key = `${k.substr(0, 1).toUpperCase()}${k.substr(1)}`;
          batchUpdates[`from${key}`] = v;
        });
      Object.entries(rawWizardData)
        .filter((pair) => pair[1])
        .forEach((keyValue) => {
          const [k, v] = keyValue;
          const key = `${k.substr(0, 1).toUpperCase()}${k.substr(1)}`;
          batchUpdates[`to${key}`] = v;
        });

      this.$confirm(`Are you sure you want to update ${this.batchQueryCount} lab results?`, "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const loadingInstance = Loading.service({ fullscreen: true });
          this.$http.post(`${window.CONFIG.api}/labs/batch`, batchUpdates).then(
            (response) => {
              loadingInstance.close();
              this.batchEditClosed();
              this.reloadCases();
            },
            (error) => {
              throw Error(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Error applying batch edit",
          });
        });
    },
    batchEditClosed() {
      this.showBatchEditWizard = false;
      this.$nextTick(() => {
        this.selectLabIdVisible = false;
      });
    },
    batchLabEntry() {
      this.labResultsWizardMode = "new";
      this.showLabResultsForm({ _isBatch: true });
    },
    deleteCase: function (event, row) {
      event.stopPropagation();
      this.$confirm("Delete this record?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          var loadingInstance = Loading.service({ fullscreen: true });
          this.$http.delete(`${window.CONFIG.api}/labs/${row.id}`).then(
            (response) => {
              loadingInstance.close();
              this.reloadCases();
            },
            (error) => {
              throw Error(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    newSypmtomsSelected: function () {
      console.log(JSON.stringify(this.newCase.symptoms));
      // console.log(tagsEl)
      // console.log(tagsEl.nodeName)
      // console.log(tagsEl.children)

      var vm = this;
      this.$nextTick(function () {
        console.log(" --- this.$refs ---");
        console.log(vm.$refs);

        var tagsEl = vm.$refs.newcasesymptoms.$refs.tags.firstChild;
        for (var i = 0; i < tagsEl.children.length; i++) {
          var el = tagsEl.children[i];
          console.log(el.textContent);
          for (var j = 0; j < vm.symptoms.length; j++) {
            var s = vm.symptoms[j];
            if (s.custom) {
              console.log("custom skipped");
              continue;
            }
            if (el.textContent === s.displayText) {
              if (el.classList.contains("el-tag--primary")) {
                el.classList.remove("el-tag--primary");
              }
              var tagType = "el-tag--" + vm.tagType(s);
              // console.log(tagType)
              if (!el.classList.contains(tagType)) {
                el.classList.add(tagType);
              }
              break;
            }
          }
          // console.log(vm.symptoms)
        }
      });
    },
    loadSummary: function () {
      // period (monthly | weekly | daily)
      // start & end
      const parameters = this.getFiltersAsQueryString();
      this.$http.get(`${window.CONFIG.api}/labs/summary?${parameters}`).then(
        (response) => {
          this.processSummary(response.body);
        },
        (error) => {
          throw Error(error);
        }
      );
    },
    returnSummaryItem(record, clientOrStaffKeyPrefix) {
      const base = {
        label: "",
        positive: 0,
        pending: 0,
        negative: 0,
        assumednegative: 0,
        indeterminate: 0,
        performed: 0,
        refused: 0,
        tested: 0,
      };
      const factory = (prefix) => {
        const obj = { ...base };
        Object.keys(record)
          .filter((key) => key.indexOf(prefix) !== -1)
          .forEach((key) => {
            const convertedKey = key.split(prefix)[1].toLowerCase();
            obj[convertedKey] = record[key];
          });
        obj.label = `${moment(record.dateKey).format("MMMM YYYY")} ${prefix === "staff" ? "Staff" : "Resident"}`;
        return obj;
      };
      const returnValues = [];
      if (this.selectedClientType === 0 || this.selectedClientType === 2) {
        returnValues.push(factory("staff"));
      }
      if (this.selectedClientType === 0 || this.selectedClientType === 1) {
        returnValues.push(factory("client"));
      }
      return returnValues;
    },
    processSummary: function (json) {
      this.summaryTableData = [];
      json.forEach((item) => {
        this.summaryTableData = this.summaryTableData.concat(this.returnSummaryItem(item));
      });
    },
    exportExcel: async function () {
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const columns = ["First Name", "Middle Name", "Last Name", "Individual ID", "Organism", "Result", "Linked", "Linked Infection Case ID", "Reason", "Procedure", "Source", "Lab ID", "Culture Date", "Collection Date", "Facility", "Unit", "Status", "Staff", "Position", "Notes", "Created By", "Created Date and Time", "Updated By", "Updated Date and Time"];
      const parameters = this.getFiltersAsQueryString();
      const convertDataToColumns = (json) => [
        json.client.firstName,
        json.client.middleName,
        json.client.lastName,
        this.getIdForItem(json.client),
        json.organism,
        json.result,
        json.caseId !== 0 ? "Yes" : "No",
        json.caseId || "",
        json.reason,
        json.procedure,
        json.source,
        json.labId,
        moment(json.cultureDate).format(this.$configStore.dateFormat()),
        moment(json.collectionDate).format(this.$configStore.dateFormat()),
        this.isStaff(json.client) ? this.getFacilityNamesForStaff(json.client.facilities).join(", ") : this.getFacilityNameForResident(json.client.facId),
        this.isStaff(json.client) ? "" : this.beds[json.client.bedId] ? this.beds[json.client.bedId].fullDesc : this.unitById(json.client.unitId),
        this.statusMap[json.client.status],
        this.isStaff(json.client),
        json.client.employeeType,
        json.notes,
        json.createdBy,
        moment(this.offsetDate(json.created)).format(`${this.$configStore.dateFormat()} HH:mm:ss`),
        json.updatedBy,
        json.updated ? moment(json.updated).format(`${this.$configStore.dateFormat()} HH:mm:ss`) : "",
      ];
      const fetchDataForColumns = async (page) => {
        const response = await this.$http.get(`${window.CONFIG.api}/labs?page=${page}&size=30&${parameters}`);
        return response.body;
      };
      let data = [];
      let currentPage = 0;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        data = [...data, ...json.content.map(convertDataToColumns)];
        hasMore = json.last !== true;
        currentPage++;
        this.excelExportResultsProgress = Math.round(100 * (currentPage / json.totalPages));
      }
      this.excelExportResultsVisible = false;
      if (!this.cancelExcelExportProgress) {
        this.createExcelFile("lab_report.xlsx", columns, data);
      }
    },
    cancelExcelExport() {
      this.cancelExcelExportProgress = true;
    },
    getIdForItem(item) {
      if (item.clientType !== "Staff") {
        return this.$configStore.patientIdType() ? item[this.$configStore.patientIdType()] : item.patientId;
      } else {
        return item.staffId;
      }
    },
  },
  mounted: function () {
    this.canModify = auth.canModify();
    const start = moment().subtract(30, "days").toDate();
    const end = moment().toDate();
    this.filterDateRange = [start, end];

    var vm = this;
    vm.sites = this.$configStore.data.sites || [];
    vm.units = this.$configStore.data.units || [];
    vm.rooms = this.$configStore.data.rooms || [];
    vm.beds = this.$configStore.data.bedMap || {};
    console.log("-----!!!!");
    console.log(this.beds);
    vm.symptomTypes = this.$configStore.data.symptomTypes || [];
    vm.organismOptions = this.$configStore.data.organismOptions || [];
    vm.customSymptomTypeMap = this.$configStore.data.customSymptomTypeMap || {};
    vm.symptoms = this.$configStore.data.symptoms || [];
    vm.labProceduresOptions = (this.$configStore.data.config.LabProcedures || []).map((p) => {
      return {
        label: p,
        value: p,
      };
    });
    console.log("loadCustomSypmtoms", this.symptoms);
    vm.labSourceOptions = (this.$configStore.data.config.LabSource || []).map((s) => {
      return {
        label: s,
        value: s,
      };
    });
    console.log("loadCustomSypmtoms", this.symptoms);
    this.symptoms.forEach((s) => {
      console.log(s.classType, s.displayText);
    });
    vm.organisms = this.$configStore.data.organisms || [];
    vm.precautions = this.$configStore.data.precautions || [];
    vm.treatments = this.$configStore.data.treatments || [];
    vm.labResults = this.$configStore.labResults() || [];
    vm.origins = this.$configStore.data.origins || [];
    vm.loadCases();
    vm.loadSummary();
    this.dataReady = true;

    this.clientList = [];
    if (this.sites.length === 1) {
      this.newCaseFac = this.sites[0].id;
      this.newCaseFacSelection();
    }
    this.client = this.$configStore.client || {};

    vm.$watch("graphPeriodFilter", function (val) {
      if (vm.graphPeriodFilter === "By Year") {
        vm.filterPeriods = "year";
      } else if (vm.graphPeriodFilter === "By Quarter") {
        vm.filterPeriods = "quarter";
      } else if (vm.graphPeriodFilter === "By Month") {
        vm.filterPeriods = "month";
      } else if (vm.graphPeriodFilter === "By Week") {
        vm.filterPeriods = "week";
      }
    });

    vm.$watch("symptomDetailPageSize", function (val) {
      vm.symptomDetailPage = 1;
      vm.loadCases();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-dialog .el-row {
  line-height: 3em;
}

.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

/* .new-case-dialog div {
    line-height: unset
} */

.delete-dialog div {
  line-height: 3em;
}

.delete-dialog .el-dialog div.el-dialog__body {
  padding-top: 0;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}
.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.el-popover .el-row {
  line-height: 2.5em;
  font-family: sans-serif;
  font-size: 1.2em;
}

.el-popover .el-row .el-switch {
  float: right;
}

.dialog-footer {
  float: right;
  margin: 1em;
  margin-top: 2em;
}

.new-case-dialog .el-form-item__error {
  line-height: normal !important;
}
</style>

<style>
.batch-edit .el-form-item__label {
  width: 100px;
}
.new-case-dialog .el-form-item__label {
  width: 130px;
}
.new-case-dialog .el-form-item__error {
  line-height: 1;
  margin-left: 130px;
}
.new-case-dialog .labid .el-input {
  line-height: 1;
  width: 200px;
}
.new-case-dialog .el-textarea {
  width: 400px;
}
.new-case-dialog .el-form .profile-photo {
  top: 70px;
}
.new-case-dialog .el-form .new-case-name {
  font-weight: bold;
  margin: 0 0 0 20px;
}
table.batchRsultsPreview td,
table.batchRsultsPreview th {
  padding: 3px 5px;
}
</style>
