<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :size="dialogSize">
      <el-form v-loading="dialogBusy" v-if="formVisibility" ref="resolutionForm" :model="formModel" :rules="formRules" label-width="140px">
        <!-- Resolution Date -->
        <el-form-item label="Resolution Date" prop="resolutionDate">
          <el-date-picker v-model="formModel.resolutionDate" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Select date" :picker-options="pickerOptions"> </el-date-picker>
        </el-form-item>
        <!-- Outcome -->
        <el-form-item label="Outcome" prop="outcome">
          <el-select v-model="formModel.outcome">
            <el-option v-for="outcome in outcomes" :key="outcome" :label="outcome" :value="outcome"> </el-option>
          </el-select>
          <br />
          <el-checkbox v-model="showOutcomeSubType" :disabled="!formModel.outcome">Add Outcome Detail</el-checkbox>
        </el-form-item>
        <el-form-item v-if="showOutcomeSubType" label="Outcome Detail" prop="outcomeSubType">
          <el-select v-model="formModel.outcomeSubType" :disabled="!formModel.outcome">
            <el-option v-for="subtype in outcomeSubTypes" :key="subtype" :label="subtype" :value="subtype"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- Precautions table (following resolution if there are open precautions) -->
      <precautions-table ref="precautionsTable" v-if="precautionsVisibility" :precautions="precautionsConfig" :caseData="infectionCase" :clientData="infectionCase.client" :showOnlyActiveLinked="true" @resolution-close="closeForm" />
      <div class="dialog-footer">
        <el-row>
          <el-button v-if="showResolveAll" @click="resolveAllPrecautions" :disabled="dialogBusy" type="primary">End Precautions Now</el-button>
          <el-button v-if="showClose" @click="closeForm" :disabled="dialogBusy">Close</el-button>
          <el-button v-if="showCancel" @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button v-if="formVisibility" type="primary" @click="commitForm()" :disabled="dialogBusy">Confirm</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Precautions from "../Precautions/Tab";
import moment from "moment";
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
export default {
  name: "resolve-infection-case",
  props: ["infectionCase"],
  components: {
    "precautions-table": Precautions,
  },
  data() {
    return {
      title: "Resolve Infection Case",
      dialogSize: "small",
      showCancel: true,
      showClose: false,
      showResolveAll: false,
      formVisibility: true,
      showOutcomeSubType: false,
      precautionsVisibility: false,
      dialogVisibility: true,
      dialogBusy: true,
      outcomes: [],
      outcomeSubTypes: [],
      outcomeConfig: {},
      precautionsConfig: {},
      updatedInfectionCase: {},
      pickerOptions: {
        disabledDate: (time) => {
          const today = moment().startOf("day");
          // onsetDate vs onSetDate is for compatibility with legacy model
          const onsetDate = moment(this.$props.infectionCase.onSetDate || this.$props.infectionCase.onsetDate).startOf("day");
          // disable dates before onset & after current date
          return moment(time).isBefore(onsetDate, "day") || moment(time).isAfter(today, "day");
        },
      },
      formRules: {
        resolutionDate: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        outcome: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
      },
      formModel: {
        resolutionDate: "",
        outcome: "",
        outcomeSubType: "",
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (!val) {
        this.cancelForm();
      }
    },
    "formModel.outcome"(val) {
      this.formModel.outcomeSubType = "";
      this.outcomeSubTypes = this.outcomeConfig[val] || [];
    },
  },
  methods: {
    cancelForm() {
      this.$emit("resolution-cancel");
    },
    closeForm() {
      this.$emit("resolution-close", this.updatedInfectionCase);
    },
    resolveAllPrecautions() {
      this.$refs.precautionsTable?.resolveAllPrecautions();
    },
    async commitForm() {
      this.$refs.resolutionForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogBusy = true;
            // setup request body
            const raw = { ...this.formModel };
            const caseResolution = {
              resolutionDate: raw.resolutionDate,
              outcome: raw.outcome,
              outcomeSubType: raw.outcomeSubType || null,
              resolved: true,
            };
            // resolve case
            await this.$http.post(`${window.CONFIG.infection_api}/${this.infectionCase.id}/resolve`, JSON.stringify(caseResolution));
            this.updatedInfectionCase = { ...this.infectionCase, ...caseResolution };
            // show precautions table if there are any open precautions
            const hasActivePrecautions = await this.hasActivePrecautions();
            this.dialogBusy = false;
            if (hasActivePrecautions) {
              this.dialogSize = "large";
              this.title = "Resolve Precautions";
              this.showCancel = false;
              this.showClose = true;
              this.showResolveAll = true;
              this.formVisibility = false;
              this.precautionsVisibility = true;
            } else {
              this.$emit("resolution-save", this.updatedInfectionCase);
            }
          } catch (error) {
            this.$emit("resolution-error");
          }
        } else {
          return false;
        }
      });
    },
    async hasActivePrecautions() {
      return this.$http
        .get(window.CONFIG.precaution_api, {
          params: {
            clientId: this.infectionCase.client.id,
            symptomDetailId: this.infectionCase.id,
          },
        })
        .then((resp) => resp.json())
        .then((json) => {
          const openPrecautions = json.content.filter(this.precautionIsActive);
          return openPrecautions?.length;
        });
    },
    async fetchOutcomeConfig() {
      return this.$http.get(`${window.CONFIG.infection_api}/outcomes`).then((resp) => resp.json());
    },
    precautionIsActive(precaution) {
      const { startDate, endDate } = precaution;
      // precaution is active when there's no end date
      if (!endDate) {
        return true;
      }
      // also active if between the start and end dates
      const now = new Date();
      return now >= new Date(startDate) && now <= new Date(endDate);
    },
  },
  async created() {
    // if updating a case resolution
    if (this.infectionCase.resolutionDate) {
      this.title = "Update Case Resolution";
    }
    const outcomeConfig = await this.fetchOutcomeConfig();
    this.outcomeConfig = outcomeConfig;
    this.outcomes = Object.keys(outcomeConfig) || [];
    this.precautionsConfig = this.$configStore.data.precautions;
    this.dialogBusy = false;
  },
  mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  },
};
</script>