<template>
  <div class="splash" style="width: 100%; height: 100%" v-loading.body="loadingData">
    <div class="filter">
      <div class="section-header">
        <el-row><span>Dashboard</span></el-row>
      </div>
      <location-filter v-if="sites.length > 1" @update:filter="locationFilterUpdated"> </location-filter>
      <el-row class="filter-group-label" v-if="sites.length > 1">Infection Site Filter:</el-row>
      <inf-filter :sections="filterSections" @update:filter="filterUpdated" :picker-options="pickerOptions"></inf-filter>
    </div>
    <div class="section-header">
      <el-row>
        Case Frequency
        <div class="graph-filter">
          <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportGraph()">Export Graph</el-button>
          <el-radio-group v-model="graphPeriodFilter" size="mini">
            <el-radio-button label="By Year"></el-radio-button>
            <el-radio-button label="By Quarter"></el-radio-button>
            <el-radio-button label="By Month"></el-radio-button>
            <el-radio-button label="By Week"></el-radio-button>
          </el-radio-group>
          <el-radio-group v-model="graphDisplayFilter" size="mini">
            <el-radio-button label="By Unit"></el-radio-button>
            <el-radio-button label="By Infection Site"></el-radio-button>
          </el-radio-group>
        </div>
      </el-row>
    </div>
    <div class="graph">
      <inf-case-graph ref="infCaseGraph" :range="filterDateRange" :period="filterPeriods" :sites="filterSymptomTypes" :subSiteType="filteredSiteSubType" :origin="filterOrigin" :confirmed="filterConfirmStatus" :selectedClientStatus="selectedClientStatus" :config="graphConfig" :mode="graphMode" :unitId="selectedUnit" :units="availableUnits" :facIds="availableFacs" />
    </div>
    <div class="section-header">
      <el-row
        ><span>Case Detail</span>
        <div class="case-filter">
          <!-- <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportExcel()">Export Data</el-button> -->
          <el-select class="case-page-size-filter" size="mini" v-model="symptomDetailPageSize" placeholder="Choose tags for your article">
            <el-option v-for="item in symptomDetailPageSizeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-pagination small layout="prev, pager, next" :current-page="symptomDetailPage" :page-count="symptomDetailPageTotal" v-on:current-change="reloadPage"> </el-pagination>
        </div>
      </el-row>
    </div>
    <el-dialog class="case-detail-dialog" title="Case Detail" v-if="selectedCase" v-model="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false">
      <!--<symptom-case-detail
       :caseData="selectedCase"
       :clientData="clients[selectedCase.clientId]"
       :origins="origins"
       :organisms="organisms"
       :treatments="treatments">
      </symptom-case-detail>-->
      <surveillance-case-detail :caseData="selectedCase" :clientData="clients[selectedCase.clientId]" :origins="origins" :precautions="precautions" :organisms="organisms" :symptomTypes="symptomTypes" :treatments="treatments"> </surveillance-case-detail>
      <span slot="footer" class="dialog-footer" v-if="canModify">
        <el-button
          @click="
            dialogFormVisible = false;
            this.selectedCase = null;
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="saveSymtomDetail(selectedCase)">Update</el-button>
      </span>
    </el-dialog>
    <el-table :data="cases" style="width: 100%" v-on:row-click="selectedRow" :default-sort="{ prop: 'onSetDate', order: 'descending' }">
      <el-table-column fixed label=" " width="100">
        <template scope="scope">
          <div style="padding: 0.5em">
            <div :span="4" class="profile-row-photo" v-if="scope.row.client"><img :src="clientPhoto(scope.row.client)" /></div>
            <div :span="4" class="profile-row-photo" v-else><img src="/static/user.png" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name" sortable fixed width="180">
        <template scope="scope">
          <span v-if="scope.row.client">{{ clientName(scope.row) }}<br />({{ isStaff(scope.row.client) ? scope.row.client.staffId : $configStore.patientIdType() ? scope.row.client[$configStore.patientIdType()] : scope.row.client.patientId }})</span>
          <span v-else>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Infection Site" width="160">
        <template scope="scope">
          <span class="color-code-label-0" v-if="scope.row.symptomType === 'GI'"></span>
          <span class="color-code-label-2" v-else-if="scope.row.symptomType === 'UTI'"></span>
          <span class="color-code-label-1" v-else-if="scope.row.symptomType === 'Respiratory'"></span>
          <span class="color-code-label-3" v-else></span>
          {{ scope.row.symptomType || scope.row.infectionSite }} {{ scope.row.subtypeString ? " / " + scope.row.subtypeString : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Status" width="80">
        <template scope="scope">
          <img class="symptom-status-image" :src="statusImage(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="Symptoms" min-width="160">
        <template scope="scope">
          <el-tag v-for="d in scope.row.symptoms" :key="d.id" :type="tagType(d)">{{ d.displayText }}</el-tag>
          <el-tag v-for="o in scope.row.otherSymptoms" :key="o">{{ o.displayText }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Precautions" min-width="160">
        <template scope="scope">
          <div v-if="clients[scope.row.clientId]">
            <el-tag v-for="d in clients[scope.row.clientId].precautionDetails" v-bind:key="d.precautionId">{{ precautionById(d.precautionId) }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <!--<el-table-column
        label="Resolution"
        width="220">
        <template scope="scope">
          {{ scope.row.resolved }}
        </template>
      </el-table-column>-->

      <el-table-column label="Facility" width="160">
        <template scope="scope">
          {{
            isStaff(scope.row.client)
              ? scope.row.client.facilities
                  .map(facilityNameByFacilityId)
                  .filter((site) => site)
                  .join(", ")
              : facilityNameByUnitId(scope.row.unitId)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Location" width="160">
        <template scope="scope">
          {{ beds[scope.row.bedId] ? beds[scope.row.bedId].fullDesc : unitById(scope.row.unitId) }}
        </template>
      </el-table-column>
      <el-table-column label="Origin" width="200">
        <template scope="scope">
          {{ originById(scope.row.origin) }}
        </template>
      </el-table-column>
      <el-table-column label="Primary Organism" width="220">
        <template scope="scope">
          {{ scope.row.primaryOrganism || "Not Selected" }}
        </template>
      </el-table-column>
      <el-table-column label="Treatment" width="220">
        <template scope="scope">
          {{ getTreatments(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Created" prop="created" width="140">
        <template scope="scope">
          {{ scope.row.created | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Created By" prop="createdBy" width="140">
        <template scope="scope">
          {{ scope.row.createdBy || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Updated" prop="updated" width="140">
        <template scope="scope">
          {{ scope.row.updated | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Updated By" prop="updatedBy" width="140">
        <template scope="scope">
          {{ scope.row.updatedBy || "" }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Onset Date" prop="onSetDate" sortable width="140">
        <template scope="scope">
          {{ scope.row.onSetDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
    </el-table>
    <progress-overlay v-if="excelExportResultsVisible" :progress="excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>
  </div>
</template>

<script>
import InfCaseGraph from "../components/InfCaseGraph";
import SymptomCaseDetail from "../components/SymptomCaseDetail";
import SurveillanceCaseDetail from "../components/SurveillanceCaseDetail";
import { Loading } from "element-ui";
import auth from "../auth";
import api from "../services/restClient";
import Filter from "../components/FilterComponent/Filter";
import LocationFilter from "../components/LocationFilter/LocationFilter";
import { last, take, takeUntil } from "rxjs/operators";
import ProgressOverlay from "../components/ProgressBarOverlay";
import XLSX from "xlsx";

import moment from "moment";

export default {
  name: "splash",
  components: {
    "inf-case-graph": InfCaseGraph,
    "symptom-case-detail": SymptomCaseDetail,
    "surveillance-case-detail": SurveillanceCaseDetail,
    "inf-filter": Filter,
    "location-filter": LocationFilter,
    "progress-overlay": ProgressOverlay,
  },
  data: function () {
    return {
      cancelExcelExportProgress: false,
      excelExportResultsVisible: false,
      excelExportResultsProgress: 0,
      labReports: {},
      filterAsProps: {},
      ready: false,
      canModify: false,
      filterSections: [],
      filteredSiteSubType: 0,
      initialRun: true,
      loadingData: false,
      loadingView: null,
      currentDate: new Date(),
      cases: [],
      precautions: [],
      beds: {},
      symptomTypes: [],
      searchNameInput: "",
      graphConfig: {},
      graphMode: "site",
      clients: {},
      origins: [],
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4",
      },
      selectedCase: null,
      selectedUnit: 0,
      selectedSite: 0,
      availableUnits: [],
      availableFacs: 0,
      selectedOrigin: 0,
      dialogFormVisible: false,
      selectedStatus: 0,
      symptomDetailPage: 1,
      symptomDetailPageSize: 10,
      symptomDetailPageTotal: 0,
      symptomDetailPageSizeOptions: [
        {
          label: "5 items per page",
          value: 5,
        },
        {
          label: "10 items per page",
          value: 10,
        },
        {
          label: "20 items per page",
          value: 20,
        },
        {
          label: "50 items per page",
          value: 50,
        },
      ],
      selectedClientStatus: "C",
      siteAndTypeOptionsSelection: [0],
      filterDateRange: "",
      filteredSite: {},
      filterPeriods: "week",
      filterSymptomTypes: [],
      filterConfirmStatus: null,
      filterOrigin: null,
      organisms: [],
      treatments: [],
      organization: [],
      units: [],
      sites: [],
      symptomTypeMap: {},
      graphPeriodFilter: "By Week",
      graphDisplayFilter: "By Infection Site",
      pickerOptions: {
        shortcuts: [
          {
            text: "Last 7 Days",
            onClick(picker) {
              const start = moment().subtract(6, "days").toDate();
              const end = moment().toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 30 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(30, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 90 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment().subtract(90, "days").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = moment().weekday(-1).toDate();
              const start = moment().weekday(-7).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const lastMonth = moment().subtract(1, "months");
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const lastYear = moment().subtract(1, "years");
              const end = lastYear.endOf("year").toDate();
              const start = lastYear.startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current week",
            onClick(picker) {
              const end = moment().weekday(6).toDate();
              const start = moment().weekday(0).toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current month",
            onClick(picker) {
              const lastMonth = moment();
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Current year",
            onClick(picker) {
              const end = moment().endOf("year").toDate();
              const start = moment().startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    loadingData: function (val) {
      console.log("loadingData: ", val);
    },
  },
  methods: {
    getLocation: function (client) {
      let result = "";
      if (!this.isStaff(client)) {
        if (client.bed && client.bed.fullDesc) {
          result = client.bed.fullDesc;
        } else {
          result = this.unitById(client.unitId);
        }
      }
      return result;
    },
    buildFilter: function () {
      let filterSections = [];
      let section = {
        name: null,
        filters: [],
      };

      // onset date range
      section.filters.push({
        name: "Onset Date",
        selection: this.filterDateRange,
        selectionKey: "date",
        type: "daterange",
      });

      // fac
      if (this.sites.length === 1) {
        console.log("build site filter");
        let facFilter = {
          name: "Facility",
          selection: this.selectedSite,
          selectionKey: "fac",
          type: "options",
        };
        let facOptions = [
          {
            name: "All Facilities",
            value: 0,
          },
        ];
        this.sites.forEach((s) => {
          facOptions.push({
            name: s.displayText,
            value: s.id,
          });
        });
        facFilter.options = facOptions;
        section.filters.push(facFilter);
      }

      // units
      if (this.sites.length === 1) {
        let unitFilter = {
          name: "Unit",
          selection: this.selectedUnit,
          selectionKey: "unit",
          type: "options",
        };
        let unitOptions = [
          {
            name: "All Units",
            value: 0,
          },
        ];

        this.units.forEach((u) => {
          if (this.selectedSite === 0 || u.site.id === this.selectedSite) {
            // console.log(JSON.stringify('Site: ' + u.site.displayText, ' ', 2))
            unitOptions.push({
              name: u.displayText,
              value: u.id,
            });
          }
        });
        unitFilter.options = unitOptions;
        section.filters.push(unitFilter);
      }

      // symptoms
      let symptomFilter = {
        name: "Infection Site",
        selection: this.siteAndTypeOptionsSelection,
        selectionKey: "site",
        type: "subOptions",
      };
      // let symptomOptions = [{
      //   name: 'All Infection Sites',
      //   value: 0
      // }]
      // this.symptomTypes.forEach(s => {
      //   if (s.type !== 'Common') {
      //     symptomOptions.push({
      //       name: s.displayText,
      //       value: s.id
      //     })
      //   }
      // })
      symptomFilter.options = this.siteAndTypeOptions || [];
      console.log(symptomFilter);
      section.filters.push(symptomFilter);

      // origin
      let originFilter = {
        name: "Origin",
        selection: this.selectedOrigin,
        selectionKey: "origin",
        type: "options",
      };
      let originOptions = [
        {
          name: "All",
          value: 0,
        },
      ];
      this.origins.forEach((s) => {
        originOptions.push({
          name: s.label,
          value: s.value,
        });
      });
      originFilter.options = originOptions;
      section.filters.push(originFilter);

      // case status
      let statusFilter = {
        name: "Case Status",
        selection: this.selectedStatus,
        selectionKey: "status",
        type: "options",
      };
      let statusOptions = [
        {
          name: "All",
          value: 0,
        },
        {
          name: "Confirmed",
          value: 1,
        },
        {
          name: "Suspected",
          value: 2,
        },
      ];
      statusFilter.options = statusOptions;
      section.filters.push(statusFilter);

      // resident status
      let clientStatusFilter = {
        name: "Individual Status",
        selection: this.selectedClientStatus,
        selectionKey: "clientstatus",
        type: "options",
      };
      let clientStatusOptions = [
        {
          name: "All",
          value: 0,
        },
        {
          name: "Active",
          value: "C",
        },
        {
          name: "Transferred",
          value: "T",
        },
        {
          name: "Discharged",
          value: "D",
        },
      ];
      clientStatusFilter.options = clientStatusOptions;
      section.filters.push(clientStatusFilter);

      filterSections.push(section);
      this.filterSections = filterSections;
      console.log("filter built");
    },
    locationFilterUpdated: function (o) {
      console.log("locationFilterUpdated");
      this.filterUpdated(o);
    },
    filterUpdated: function (o) {
      console.log("filterUpdated");
      this.filterDateRange = o.date || this.filterDateRange;
      if (o.fac && this.selectedSite && o.fac !== this.selectedSite) {
        this.selectedSite = o.fac || this.selectedSite;
        // console.log('filterUpdated, buildFilter')
        this.buildFilter();
      }

      this.selectedUnit = o.unit === undefined ? this.selectedUnit : o.unit;
      this.selectedSite = o.fac === undefined ? this.selectedSite : o.fac;
      this.selectedOrigin = o.origin === undefined ? this.selectedOrigin : o.origin;
      this.selectedStatus = o.status === undefined ? this.selectedStatus : o.status;
      this.selectedClientStatus = o.clientstatus === undefined ? this.selectedClientStatus : o.clientstatus;
      this.availableUnits = o.units === undefined ? this.availableUnits : o.units;
      this.availableFacs = o.sites ? o.sites : this.availableFacs;

      console.log("filterUpdated", JSON.stringify(o));
      this.originUpdated(this.selectedOrigin);
      this.confirmStatusUpdated(this.selectedStatus);

      if (o.site !== undefined) {
        this.filteredSite = o.site[0];
        this.filteredSiteSubType = o.site[1] || 0;

        this.symptomTypeUpdated(o.site[0]);
      }
      this.loadCases();
    },
    exportGraph: function () {
      var graph = this.$refs.infCaseGraph;
      graph.exportGraph();
    },
    loadCases: function () {
      this.$emit("customEvent");
      var vm = this;
      // this.loadingData = true
      var unitId = this.selectedUnit;
      var after = this.filterDateRange[0];
      var before = this.filterDateRange[1];
      this.filterAsProps = {};

      var rUrl = window.CONFIG.api + "/symptomDetail/unit/" + unitId + "/page/" + (vm.symptomDetailPage - 1) + "?size=" + vm.symptomDetailPageSize + "&start=" + encodeURIComponent(after) + "&end=" + encodeURIComponent(before);
      rUrl = rUrl + "&types=" + this.filterSymptomTypes;
      this.filterAsProps.start = after;
      this.filterAsProps.end = before;
      this.filterAsProps.types = this.filterSymptomTypes;

      if (this.filteredSiteSubType && this.filteredSiteSubType !== 0) {
        rUrl = rUrl + "&subType=" + this.filteredSiteSubType;
        this.filterAsProps.subType = this.filteredSiteSubType;
      }

      if (this.selectedOrigin !== 0) {
        rUrl = rUrl + "&origin=" + this.selectedOrigin;
        this.filterAsProps.origin = this.selectedOrigin;
      }

      if (this.selectedStatus !== 0) {
        rUrl = rUrl + "&confirmed=" + (this.selectedStatus === 1 ? "true" : "false");
        this.filterAsProps.confirmed = this.selectedStatus === 1 ? "true" : "false";
      }

      if (this.selectedClientStatus !== 0) {
        rUrl = rUrl + "&clientStatus=" + this.selectedClientStatus;
        this.filterAsProps.clientStatus = this.selectedClientStatus;
      }

      if (this.availableUnits.length > 0) {
        rUrl = rUrl + "&units=" + this.availableUnits;
        this.filterAsProps.units = this.availableUnits;
      }

      if (this.selectedSite > 0) {
        rUrl = rUrl + "&facIds=" + [this.selectedSite];
        this.filterAsProps.facIds = [this.selectedSite];
      } else if (this.availableFacs !== 0) {
        rUrl = rUrl + "&facIds=" + this.availableFacs;
        this.filterAsProps.facIds = this.availableFacs;
      }

      var initLoad = this.initialRun;
      this.$http.get(rUrl).then(
        (response) => {
          if (response.body && response.body["content"].length > 0) {
            var cases = response.body["content"]; // response.body['_embedded'].symptomDetails
            var callbackCounter = 0;
            vm.labReports = {};

            vm.symptomDetailPageTotal = response.body["totalPages"];
            cases.forEach(function (c) {
              if (!c.origin) {
                c.origin = 0;
              }
              c.symptoms.sort(function (a, b) {
                return a.colorCode > b.colorCode;
              });

              api.getCustomSymptomForDetail(c.id, (r) => {
                c.otherSymptoms = r.body.otherSymptoms || [];
                vm.loadImage(c, function () {
                  callbackCounter++;
                  if (callbackCounter >= cases.length) {
                    vm.cases = cases;
                  }
                });
              });
              api.getLabReport(c.id, (response) => {
                if (response) {
                  vm.labReports[c.id] = response.body || [];
                }
              });
            });
          } else {
            console.log("no content");
            vm.cases = [];
          }

          if (!initLoad) {
            vm.loadingData = false;
            if (vm.loadingView) {
              vm.loadingView.close();
              vm.loadingView = null;
            }
          } else {
            console.log("initial run");
            this.buildFilter();
            this.symptomTypeUpdated();
          }
        },
        (response) => {
          // error callback
        }
      );

      if (this.initialRun) {
        this.initialRun = false;
        vm.$watch("graphPeriodFilter", function (val) {
          if (vm.graphPeriodFilter === "By Year") {
            vm.filterPeriods = "year";
          } else if (vm.graphPeriodFilter === "By Quarter") {
            vm.filterPeriods = "quarter";
          } else if (vm.graphPeriodFilter === "By Month") {
            vm.filterPeriods = "month";
          } else if (vm.graphPeriodFilter === "By Week") {
            vm.filterPeriods = "week";
          }
        });

        vm.$watch("graphDisplayFilter", function (val) {
          console.log(val);
          if (vm.graphDisplayFilter === "By Unit") {
            vm.graphMode = "unit";
          } else {
            vm.graphMode = "site";
          }
        });

        vm.$watch("symptomDetailPageSize", function (val) {
          vm.symptomDetailPage = 1;
          if (!vm.initialRun) {
            vm.loadCases();
          }
        });

        vm.ready = true;
        vm.loadingData = false;
        // if (vm.loadingView) {
        //   vm.loadingView.close()
        //   vm.loadingView = null
        // }
        vm.loadCases();
      }
    },
    nameFilter: function (cases) {
      var vm = this;
      return cases.filter(function (detail) {
        return detail.name.toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    reloadPage: function (page) {
      if (this.symptomDetailPage !== page) {
        this.symptomDetailPage = page;
        if (!this.initialRun) {
          this.loadCases();
        }
      }
    },
    loadImage: function (detail, callback) {
      var vm = this;
      if (this.clients[detail.clientId] || this.clients[detail.clientId] === false) {
        callback();
        return;
      }
      this.clients[detail.clientId] = false;
      vm.$set(vm.clients, detail.clientId, detail.client);
      vm.$forceUpdate();
      callback();
    },
    statusImage: function (detail) {
      var result = "";
      var filename;
      if (detail.symptomType) {
        filename = this.statusImageMap[detail.symptomType];
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      if (result === "") {
        filename = "bug4";
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }
      return result;
    },
    tagType: function (type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    selectedRow: function (row, event, column) {
      if (!this.symptomTypes) {
        return;
      }

      console.log(row);
      this.selectedCase = {};
      this.selectedCase = JSON.parse(JSON.stringify(row));
      if (!this.selectedCase.origin) {
        this.selectedCase.origin = 0;
      }
      // console.log(JSON.stringify(this.selectedCase, ' ', 2))
      // console.log(JSON.stringify(this.clients[this.selectedCase.clientId], ' ', 2))
      this.dialogFormVisible = true;
    },
    dateRangeUpdated: function (value) {
      // console.log(value)
      if (!value) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

        this.filterDateRange = [start, end];
      } else {
        const start = moment(this.filterDateRange[0]).hours(0).minutes(0).seconds(0).toDate();
        const end = moment(this.filterDateRange[1]).hours(23).minutes(59).seconds(59).toDate();
        console.log(start);
        console.log(end);
        this.filterDateRange = [start, end];
      }

      if (!this.initialRun) {
        this.loadCases();
      }
    },
    unitUpdated: function (value) {
      console.log("unit : " + value);
      if (!this.initialRun) {
        this.loadCases();
      }
    },
    symptomTypeUpdated: function (value) {
      console.log("symptomTypeUpdated");
      var vm = this;
      var sypmtomTypeFilter = [];
      // this.filteredSiteSubType = 0
      this.filteredSite = this.filteredSite ? this.filteredSite : 0;
      if (this.symptomTypes && Array.isArray(this.symptomTypes)) {
        this.symptomTypes.forEach(function (s, i) {
          console.log(s);
          if (vm.filteredSite === 0 || vm.filteredSite.type === s.type) {
            sypmtomTypeFilter.push(s.type);
          }
        });
      }

      this.filterSymptomTypes = sypmtomTypeFilter;
      // console.log(JSON.stringify(this.filterSymptomTypes))
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    originUpdated: function (value) {
      if (value === 0) {
        this.filterOrigin = null;
      } else {
        this.filterOrigin = value;
      }
    },
    confirmStatusUpdated: function (value) {
      console.log("confirmStatusUpdated:" + value);
      if (value === undefined || value === 0) {
        this.filterConfirmStatus = 0;
      } else {
        this.filterConfirmStatus = value;
      }
    },
    organismById: function (organId) {
      var organName = "Not Selected";
      if (organId === 0) {
        return organName;
      }

      this.organisms.forEach(function (o) {
        if (o.id === organId) {
          organName = o.name;
        }
      });

      return organName;
    },
    treatmentById: function (treatmentId) {
      var name = "Not Selected";
      if (treatmentId === 0) {
        return name;
      }

      this.treatments.forEach(function (t) {
        if (t.id === treatmentId) {
          name = t.displayText;
        }
      });

      return name;
    },
    getTreatments: function (caseData) {
      let result = "";
      caseData.treatmentDetails.forEach((d) => {
        // if (d.endDate) {
        //   return
        // }

        if (result.length > 0) {
          result = result + ", ";
        }
        if (d.customTreatment) {
          result = result + d.customTreatment;
        } else if (d.treatment && d.treatment.displayText) {
          result = result + d.treatment.displayText;
        }
      });

      if (result === "") {
        result = "Not Selected";
      }

      return result;
    },
    unitById: function (unitId) {
      var unitName = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    facilityNameByUnitId: function (unitId) {
      var name = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          name = u.site.displayText;
        }
      });

      return name;
    },
    originById: function (originId) {
      var originName = "Not Selected";
      this.origins.forEach(function (o) {
        if (o.value === originId && o.value !== 0) {
          originName = o.label;
        }
      });
      return originName;
    },
    unitByName: function (unitName) {
      var unit = null;
      this.units.forEach(function (u) {
        if (u.displayText === unitName) {
          unit = u;
        }
      });

      return unit;
    },
    precautionById: function (precautionId) {
      var name = "";
      this.precautions.forEach(function (p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    clientPhoto: function (client) {
      if (client && client.photo) {
        var f = client.photo;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "/static/" + (client.photo ? client.photo : "user") + ".png";
      }

      return "/static/user.png";
    },
    photosUrl: function (clientId) {
      if (this.clients[clientId].photo) {
        var f = this.clients[clientId].photo;
        if (f.indexOf("/") !== -1) {
          console.log(window.CONFIG.api + "/" + f);
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }
      }

      return "/static/user.png";
    },
    saveSymtomDetail: function (symptomDetail) {
      var vm = this;
      api.updateSymptomDetail(symptomDetail, function () {
        vm.loadCases();
        vm.dialogFormVisible = false;
        vm.selectedCase = null;
      });
    },
    reverse: function (array) {
      return array.slice().reverse();
    },
    clientName: function (symptomDetail) {
      var clientData = symptomDetail.client;
      this.clients[symptomDetail.clientId] = clientData;
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      return firstName + middleName + lastName;
    },
    isStaff: function (client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    facilityNameByFacilityId(facId) {
      return this.sites.filter((s) => s.id === facId).map((s) => s.displayText)[0];
    },
    getFacilityNameForResident(id) {
      return this.sites.filter((s) => s.id === id)[0].displayText;
    },
    exportExcel: async function () {
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const vm = this;
      const columns = [
        "Case Id",
        "Patient Id",
        "Given Name",
        "Middle Name",
        "Family Name",
        "Confirmed",
        "OnSet Date",
        "Infection Site",
        "Infection Site Type",
        "Symptoms",
        "Precautions",
        "Facility",
        "Location",
        "Origin",
        "Contact",
        "Primary Organism",
        "Result",
        "Culture Date",
        "Organism",
        "Treatment",
        "Staff",
        "Staff Isolation",
        "Staff Isolation Start Date",
        "Staff Isolation End Date",
        "Position",
        "Resolved",
        "Resolved Date",
        "Outcome",
        "Family Notified",
        "Manager Notified",
        "Physician/Public Health Notified",
        "Staff case reported to MoL",
        "Updated By",
        "Updated",
        "Created By",
        "Created",
        "NOTES",
      ];
      const unitId = this.selectedUnit || 0;
      const paramsToQueryString = (paramObj) => {
        let queryString = [];
        for (const [key, value] of Object.entries(paramObj)) {
          queryString.push(`${key}=${value}`);
        }
        return queryString.join("&");
      };
      const parameters = paramsToQueryString(this.filterAsProps);
      const fetchDataForColumns = async (page) => {
        const response = await this.$http.get(`${window.CONFIG.api}/symptomDetail/unit/${unitId}/page/${page}?size=30&${parameters}`);
        for (let i = 0; i < response.body.content.length; i++) {
          const labRecordsResponse = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${response.body.content[i].id}/labs`);
          response.body.content[i].labRecords = labRecordsResponse.body;
          vm.labReports[response.body.content[i].id] = labRecordsResponse.body || [];
        }
        return response.body;
      };
      const formatDate = (timestamp) => moment(timestamp).format(this.$configStore.dateFormat());
      const formatFac = (_) => (this.isStaff(_.client) ? this.getFacilityNamesForStaff(_.client.facilities).join(", ") : this.getFacilityNameForResident(_.client.facId));
      const getPrimary = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0];
      const getNonPrimary = (_) => _.labRecords.filter((r) => r.organism !== _.primaryOrganism);
      const getCultureDate = (_) => {
        const p = getPrimary(_);
        return p ? formatDate(p.cultureDate) : "";
      };
      const convertDataToColumns = (_) => [
        _.id,
        _.client.pn,
        _.client.firstName,
        _.client.middleName,
        _.client.lastName,
        _.confirmed,
        formatDate(_.onSetDate),
        _.infectionSite,
        _.subtypeString,
        _.symptoms.map((s) => s.displayText).join(", "),
        _.client.precautionDetails.map((p) => this.precautionById(p.precautionId)).join(", "),
        formatFac(_),
        this.getLocation(_.client),
        this.originById(_.origin),
        _.contact,
        _.primaryOrganism,
        _.primaryOrganism ? this.labResult(_) : "Not Selected",
        getCultureDate(_),
        getNonPrimary(_)
          .map((lr) => lr.organism)
          .join(", "),
        _.treatmentDetails.map((t) => (t.treatment ? t.treatment.displayText : "")).join(", "),
        this.isStaff(_.client),
        _.staffIsolation,
        _.isolationStart ? formatDate(_.isolationStart) : "",
        _.isolationEnd ? formatDate(_.isolationEnd) : "",
        _.client.employeeType,
        _.resolved,
        _.resolutionDate ? formatDate(_.resolutionDate) : "",
        _.outcome,
        _.familyNotified,
        _.nextOfNotified,
        _.physicianNotified,
        _.staffCaseNotified,
        _.updatedBy,
        _.updated ? formatDate(_.updated) : "",
        _.createdBy,
        _.created ? formatDate(_.created) : "",
        _.notes.map((n) => n.content).join(", "),
      ];
      let data = [];
      let currentPage = 0;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        data = [...data, ...json.content.map(convertDataToColumns)];
        hasMore = json.last !== true;
        currentPage++;
        this.excelExportResultsProgress = Math.round(100 * (currentPage / json.totalPages));
      }
      this.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onSetDate - a.onSetDate);
      if (!this.cancelExcelExportProgress) {
        this.createExcelFile("report.xlsx", columns, data);
      }
    },
    createExcelFile: function (fileName, columns, data) {
      // 20 = staff 5 = confirmed
      const confirmedStaff = [...data].filter((row) => row[20] && row[5]);
      const confirmedRes = [...data].filter((row) => !row[20] && row[5]);
      const suspectedStaff = [...data].filter((row) => row[20] && !row[5]);
      const suspectedRes = [...data].filter((row) => !row[20] && !row[5]);
      const all = data;
      confirmedStaff.unshift(columns);
      confirmedRes.unshift(columns);
      suspectedStaff.unshift(columns);
      suspectedRes.unshift(columns);
      all.unshift(columns);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(confirmedRes), "Resident Infections - Confirmed");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(confirmedStaff), "Staff Infections - Confirmed");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(suspectedRes), "Resident Infections - Suspected");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(suspectedStaff), "Staff Infections - Suspected");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(all), "data");
      XLSX.writeFile(wb, fileName);
    },
    cancelExcelExport() {
      this.cancelExcelExportProgress = true;
    },
    labResult: function (caseData) {
      let result = "Not Selected";
      let reports = this.labReports[caseData.id] || [];
      reports.forEach((l) => {
        if (l.organism === caseData.primaryOrganism) {
          result = l.result || "Not Selected";
        }
      });

      return result;
    },
  },
  created: function () {
    this.loadingView = Loading.service({ fullscreen: true });
    let beforeDestroy$ = this.$eventToObservable("hook:beforeDestroy").pipe(take(1));
    let customEvent$ = this.$eventToObservable("customEvent").pipe(last());
    customEvent$.subscribe((event) => console.log(event.name, event.msg));

    customEvent$.pipe(takeUntil(beforeDestroy$));
  },
  mounted: function () {
    this.canModify = auth.canModify();
    this.loadingData = true;
    if (!this.loadingView) {
      this.loadingView = Loading.service({ fullscreen: true });
    }
    // this.loadCases()
    const start = moment().subtract(30, "days").toDate();
    const end = moment().toDate();
    this.filterDateRange = [start, end];

    let vm = this;
    let interval = setInterval(() => {
      if (vm.$configStore.loaded) {
        clearInterval(interval);
        vm.sites = this.$configStore.data.sites || [];
        vm.units = this.$configStore.data.units || [];
        vm.rooms = this.$configStore.data.rooms || [];
        vm.beds = this.$configStore.data.bedMap || [];
        vm.symptomTypes = this.$configStore.data.symptomTypes || [];
        vm.siteAndTypeOptions = this.$configStore.data.siteAndTypeOptions || [];
        vm.customSymptomTypeMap = this.$configStore.data.customSymptomTypeMap || {};
        vm.symtpoms = this.$configStore.data.symtpoms || [];
        vm.organisms = this.$configStore.data.organisms || [];
        vm.precautions = this.$configStore.data.precautions || [];
        vm.treatments = this.$configStore.data.treatments || [];
        vm.graphConfig = { units: vm.units };
        vm.origins = this.$configStore.data.origins || [];
        console.log("load Cases", vm);
        vm.loadCases();
      } else {
        console.log("configstore not ready");
      }
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.graph-filter > .el-radio-group {
  padding: 9px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0;
}

.graph-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 9px;
}

.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}

.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.case-page-size-filter {
  float: left;
}

surveillance-case-detail {
  position: relative;
}
</style>
