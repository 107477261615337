<template>
  <div class="floor-map" v-loading.fullscreen.lock="loading" v-if="$router.currentRoute.path == '/floormap'">
    <!-- filter popover -->
    <div class="filter">
      <div class="section-header">
        <el-row> Floor Map </el-row>
      </div>
      <!-- FILTER -->
      <el-row class="filter-section">
        <template v-if="sites.length === 1">
          <label
            ><span class="filter-title-label">Unit:</span>
            <el-select v-model="selectedUnit" placeholder="Unit" class="filter-section-input" v-on:change="unitUpdated">
              <el-option label="Not Selected" :value="0"> </el-option>
              <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item.id">
                <span style="float: left">{{ item.displayText }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </label>
        </template>
        <template v-if="sites.length > 1">
        <location-filter :filterGroupLabelVisible="false" @update:filter="locationFilterUpdate"></location-filter>
          <!-- <el-row class="filter-group-label">Other Filters:</el-row> -->
          <!-- <label>
				<span class="filter-title-label">Date Range:</span>
				<el-date-picker
				class="filter-section-input"
				label="Date Range"
				v-model="filterDateRange"
				type="daterange"
				placeholder="All Dates"
				:format="$configStore.datePickerDateFormat()" range-separator=" ~ "
				:picker-options="pickerOptions"
				:clearable="true"
				small
				v-on:change="dateRangeUpdated">
				<template slot="prepend">Http://</template>
				</el-date-picker>
			</label> -->
        </template>
        <div class="historicalButtonDiv">
          <el-button v-if="selectedUnit != 0 && !viewHistoricalController" @click="historicalFloorMapVisible = true">View Historical Infections</el-button>
        </div>
      </el-row>
    </div>
    <!-- Historical Floor Map -->
    <el-dialog title="Historical Floor Map Settings" :show-close="false" :visible="historicalFloorMapVisible" size="small">
      <div class="settings-details">
        <el-form @submit.prevent="onSubmit" ref="observerForm">
          <el-form-item label="Date Range:" :rules="{ required: true }">
            <el-date-picker v-model="historicalDateRange" type="daterange" :format="$configStore.datePickerDateFormat()" :picker-options="pickerOptions" range-separator=" ~ "> </el-date-picker>
          </el-form-item>
          <el-form-item label="Player Speed (Seconds Per Interval):">
            <el-input-number v-model="controllerSpeed" :min="1" size="small"> </el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historicalFloorMapVisible = false">Cancel</el-button>
        <el-button type="primary" :disabled="historicalDateRange[0] == null" @click="startHistoricalFloormap()">Play</el-button>
      </span>
    </el-dialog>

    <!-- Detail View -->
    <el-dialog :title="clientName(selectedCase)" v-if="selectedCase" v-model="dialogFormVisible" size="large" v-on:close="loadCases">
      <el-tabs v-model="caseActiveTab" type="card">
        <el-tab-pane label="Case Detail" name="1">
          <surveillance-case-detail :caseData="selectedCase" :clientData="clients[selectedCase.clientId]" :origins="origins" :organisms="organisms" :treatments="treatments" :symptomTypes="symptomTypes" :precautions="precautions"> </surveillance-case-detail>
          <hr />
          <el-row>
            <el-col :span="5">Resolution Date:</el-col>
            <el-col :span="16">
              <el-date-picker v-model="selectedCase.resolutionDate" type="date" editable clearable :format="$configStore.datePickerDateFormat()"> </el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">Outcome:</el-col>
            <el-col :span="16">
              <el-select v-model="selectedCase.outcome" placeholder="Select">
                <el-option label="Not Selected" :value="0"> </el-option>
                <el-option v-for="item in outcomes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-col>
          </el-row>

          <div class="dialog-footer">
            <el-button
              @click="
                dialogFormVisible = false;
                this.selectedCase = null;
              "
              >Cancel</el-button
            >
            <el-button type="primary" @click="saveSymtomDetail(selectedCase)">Update</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'Notes (' + (selectedCase.notes ? selectedCase.notes.length : 0) + ')'" name="2">
          <surveillance-communication :caseData="selectedCase" :clientData="clients[selectedCase.clientId]" :notes="selectedCase.notes"> </surveillance-communication>
        </el-tab-pane>
        <el-tab-pane label="Biology" name="3">
          <surveillance-biology :caseData="selectedCase" :organisms="organisms"> </surveillance-biology>
          <div class="dialog-footer">
            <el-button
              @click="
                dialogFormVisible = false;
                this.selectedCase = null;
              "
              >Cancel</el-button
            >
            <el-button type="primary" @click="saveSymtomDetail(selectedCase)">Update</el-button>
          </div>
        </el-tab-pane>
        <!--        <el-tab-pane label="Device" name="4">
		<surveillance-device>
		</surveillance-device>
		</el-tab-pane>-->
        <el-tab-pane label="Signs and Symptoms" name="5">
          <surveillance-symptoms :caseData="selectedCase" :symptomTypeMap="symptomTypeMap" :customSymptomTypeMap="customSymptomTypeMap" :symptoms="symptoms"> </surveillance-symptoms>
          <div class="dialog-footer">
            <el-button
              @click="
                dialogFormVisible = false;
                this.selectedCase = null;
              "
              >Cancel</el-button
            >
            <el-button :disabled="!canSave(selectedCase)" type="primary" @click="saveSymtomDetail(selectedCase)">Update</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Treatment" name="6">
          <surveillance-treatment :caseData="selectedCase" :treatments="treatments"> </surveillance-treatment>
        </el-tab-pane>
        <el-tab-pane :label="'Precaution (' + (clients[selectedCase.clientId].precautionDetails ? clients[selectedCase.clientId].precautionDetails.length : 0) + ')'" name="8">
          <surveillance-precaution :caseData="selectedCase" :precautions="precautions" :clientData="clients[selectedCase.clientId]"> </surveillance-precaution>
        </el-tab-pane>
        <el-tab-pane label="Individual" name="7">
          <surveillance-personal-info :clientData="clients[selectedCase.clientId]" v-on:change="updatePersonalNotes"> </surveillance-personal-info>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog title="Delete" v-model="dialogConfirmDeleteVisible" v-if="selectedCaseToDelete" class="delete-dialog">
      <div>
        <el-row>
          <el-col :span="5"><span>Infection Site: </span></el-col>
          <el-col :span="16">
            <span class="color-code-label-0" v-if="selectedCaseToDelete.symptomType === 'GI'"></span>
            <span class="color-code-label-2" v-else-if="selectedCaseToDelete.symptomType === 'UTI'"></span>
            <span class="color-code-label-1" v-else-if="selectedCaseToDelete.symptomType === 'Respiratory'"></span>
            <span class="color-code-label-3" v-else></span>
            {{ selectedCaseToDelete.symptomType }}
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogConfirmDeleteVisible = false;
            selectedCaseToDelete = null;
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="confirmDeleteCase()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- DETAIL -->
    <div v-if="viewHistoricalController" class="controller">
      <el-row>
        <el-button class="historicalSettings" size="small" @click="historicalFloorMapVisible = true">Settings</el-button>
        <el-button class="controller-buttons" @click="sliderButtonPress('stop')"
          ><svg xmlns="http://www.w3.org/2000/svg" :height="controllerButtonSize" viewBox="0 0 24 24" :width="controllerButtonSize" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M16 8v8H8V8h8m2-2H6v12h12V6z" /></svg
        ></el-button>
        <el-button class="controller-buttons" @click="sliderButtonPress('backward')"
          ><svg xmlns="http://www.w3.org/2000/svg" :height="controllerButtonSize" viewBox="0 0 24 24" :width="controllerButtonSize" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18 9.86v4.28L14.97 12 18 9.86m-9 0v4.28L5.97 12 9 9.86M20 6l-8.5 6 8.5 6V6zm-9 0l-8.5 6 8.5 6V6z" /></svg
        ></el-button>
        <el-button class="controller-buttons" @click="sliderButtonPress('play')" size="small" v-if="!intervalValue"
          ><svg xmlns="http://www.w3.org/2000/svg" :height="controllerButtonSize" viewBox="0 0 24 24" :width="controllerButtonSize" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" /></svg
        ></el-button>
        <el-button class="controller-buttons" @click="sliderButtonPress('pause')" size="small" v-if="intervalValue"
          ><svg xmlns="http://www.w3.org/2000/svg" :height="controllerButtonSize" viewBox="0 0 24 24" :width="controllerButtonSize" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" /></svg
        ></el-button>
        <el-button class="controller-buttons" @click="sliderButtonPress('forward')"
          ><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" :height="controllerButtonSize" viewBox="0 0 24 24" :width="controllerButtonSize" fill="#000000">
            <g>
              <rect fill="none" height="24" width="24" />
              <rect fill="none" height="24" width="24" />
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g />
              <path d="M15,9.86L18.03,12L15,14.14V9.86 M6,9.86L9.03,12L6,14.14V9.86 M13,6v12l8.5-6L13,6L13,6z M4,6v12l8.5-6L4,6L4,6z" />
            </g></svg
        ></el-button>
        <span class="current-date-display"> Current Date: {{ formatSliderTooltip(sliderDate) }}</span>
        <el-button class="close-historical-button" type="text" size="small" @click="resetFloormap()"
          ><svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg
        ></el-button>
      </el-row>
      <el-row>
        <el-col class="edge-dates" :span="3">
          {{ formatSliderTooltip(0) }}
        </el-col>
        <el-col :span="18">
          <el-slider v-model="sliderDate" :min="0" :max="dateRangeLength" :step="1" :format-tooltip="formatSliderTooltip" :show-tooltip="false"> </el-slider>
        </el-col>
        <el-col class="edge-dates" :span="3">
          {{ formatSliderTooltip(dateRangeLength) }}
        </el-col>
      </el-row>
      <el-row>
        <el-col> </el-col>
      </el-row>
    </div>
    <div class="legend">
      <div class="uti">UTI</div>
      <div class="respiratory">Respiratory</div>
      <div class="gi">GI</div>
      <div class="skin">Skin & Soft tissue</div>
    </div>
    <div class="floor" v-if="ready">
      <hcx-floormap ref="floormap"></hcx-floormap>
    </div>
  </div>
</template>

<script>
import auth from "../auth";
import api from "../services/restClient";

// import '../../lib/elements/hcx-floormap.js'
// import '../../lib/elements/styles.css'

import moment from "moment";
import { Loading } from "element-ui";
import SurveillanceCaseDetail from "../components/SurveillanceCaseDetail";
import SurveillanceCommunication from "../components/SurveillanceCommunication";
import SurveillanceBiology from "../components/SurveillanceBiology";
import SurveillanceDevice from "../components/SurveillanceDevice";
import SurveillanceSymptoms from "../components/SurveillanceSymptoms";
import SurveillanceTreatment from "../components/SurveillanceTreatment";
import SurveillancePersonalInfo from "../components/SurveillancePersonalInfo";
import SurveillancePrecaution from "../components/SurveillancePrecaution";
import LocationFilter from "../components/LocationFilter/LocationFilter";
import transformer from "@/transformer";

export default {
  name: "floormap",
  components: {
    "surveillance-case-detail": SurveillanceCaseDetail,
    "surveillance-communication": SurveillanceCommunication,
    "surveillance-biology": SurveillanceBiology,
    "surveillance-device": SurveillanceDevice,
    "surveillance-symptoms": SurveillanceSymptoms,
    "surveillance-treatment": SurveillanceTreatment,
    "surveillance-personal-info": SurveillancePersonalInfo,
    "surveillance-precaution": SurveillancePrecaution,
    "location-filter": LocationFilter
  },
  data: function() {
    return {
      // historical floor plan data
      historicalDateRange: [null],
      dateRangeLength: 1,
      sliderDate: 1,
      viewHistoricalController: false,
      controllerButtonSize: "15px",
      initialCaseDict: {},
      intervalValue: null,
      controllerSpeed: 1,
      // HFP Data
      ready: false,
      fixClick: false,
      historicalFloorMapVisible: false,
      filterSelectionVisible: false,
      loading: false,
      firstLoad: false,
      currentDate: new Date(),
      cases: [],
      precautions: [],
      beds: {},
      symptomTypes: [],
      symptoms: [],
      searchNameInput: "",
      clients: {},
      infCases: {},
      clientList: [],
      clientService: {},
      symptomTypeMap: {},
      customSymptomTypeMap: {},
      origins: [],
      outcomes: [
        {
          value: "Chronic",
          label: "Chronic"
        },
        {
          value: "Deceased",
          label: "Deceased"
        },
        {
          value: "Discharged",
          label: "Discharged"
        },
        {
          value: "Hospitalized",
          label: "Hospitalized"
        },
        {
          value: "Resolved",
          label: "Resolved"
        }
      ],
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4"
      },
      allFilters: {
        dateRange: true,
        unit: true,
        infSite: true,
        status: true,
        origin: true,
        treatment: false,
        precaution: false,
        organism: false,
        resolution: false,
        familyNotified: false,
        nextOfKinNotified: false,
        physicianNotified: false,
        staffOrRes: false,
        firstName: false,
        lastName: false
      },
      siteAndTypeOptionsSelection: [0],
      caseActiveTab: "1",
      selectedClientStatus: 1,
      selectedCase: null,
      selectedClient: null,
      selectedUnit: 0,
      selectedUnitDetail: null,
      selectedOrigin: 0,
      selectedStatus: 0,
      selectedTreatment: 0,
      selectedPrecautions: 0,
      selectedOrganisms: 0,
      selectedResolved: 0,
      selectedFamilyNotified: 0,
      selectedNextOfNotified: 0,
      selectedPhysicianNotified: 0,
      selectedClientType: 0,
      selectedSite: 0,
      selectedFirstName: "",
      selectedLastName: "",
      selectedCaseToDelete: null,
      dialogFormVisible: false,
      newCaseDialogFormVisible: false,
      includeDischargedClient: false,
      dialogConfirmDeleteVisible: false,
      newCase: {
        client: null,
        symptoms: [],
        error: null,
        subtypeString: ""
      },
      subStatusMap: {
        GI: ["Noro Virus", "CDI", "GE"],
        UTI: ["ABUTI", "SUTI", "ASB"],
        Respiratory: ["Upper/Common Cold", "Lower/Pnem/Bron", "Flu - Influezna Like", "SINU"]
      },
      symptomDetailPage: 1,
      symptomDetailPageSize: 1000,
      symptomDetailPageTotal: 0,
      symptomDetailTotal: 0,
      filterDateRange: "",
      filteredSite: {},
      filterPeriods: "week",
      filterSymptomTypes: [],
      filteredSiteSubType: 0,
      siteAndTypeOptions: [],
      availableUnits: [],
      organisms: [],
      treatments: [],
      units: [],
      unitMap: {},
      sites: [],
      organization: [],
      graphPeriodFilter: "By Week",
      graphDisplayFilter: "By Infection Site",
      pickerOptions: {
        shortcuts: [
          {
            text: "Last 7 Days",
            onClick(picker) {
              const start = moment()
                .subtract(6, "days")
                .toDate();
              const end = moment().toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 30 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment()
                .subtract(30, "days")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 90 Days",
            onClick(picker) {
              const end = moment().toDate();
              const start = moment()
                .subtract(90, "days")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = moment()
                .weekday(-1)
                .toDate();
              const start = moment()
                .weekday(-7)
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const lastMonth = moment().subtract(1, "months");
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last year",
            onClick(picker) {
              const lastYear = moment().subtract(1, "years");
              const end = lastYear.endOf("year").toDate();
              const start = lastYear.startOf("year").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current week",
            onClick(picker) {
              const end = moment()
                .weekday(6)
                .toDate();
              const start = moment()
                .weekday(0)
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current month",
            onClick(picker) {
              const lastMonth = moment();
              const end = lastMonth.endOf("month").toDate();
              const start = lastMonth.startOf("month").toDate();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Current year",
            onClick(picker) {
              const end = moment()
                .endOf("year")
                .toDate();
              const start = moment()
                .startOf("year")
                .toDate();
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  watch: {
    sliderDate: function(val) {
      this.displayHistoricalFloormap(val);
    },
    selectedUnit: function() {
      if (this.viewHistoricalController) {
        this.viewHistoricalController = false;
      }
    }
  },
  methods: {
    formatSliderTooltip: function(val) {
      return moment(this.historicalDateRange[0])
        .add(val, "days")
        .format(this.$configStore.dateFormat());
    },
    sliderButtonPress: function(event) {
      if (event === "play") {
        this.intervalValue = setInterval(() => {
          console.log("sliderDate++");
          if (this.sliderDate < this.dateRangeLength) {
            this.sliderDate++;
          } else {
            this.sliderButtonPress("pause");
          }
        }, this.controllerSpeed * 1000);
      }
      if (event === "pause" && this.intervalValue) {
        clearInterval(this.intervalValue);
        this.intervalValue = null;
      }
      if (event === "forward" && this.sliderDate < this.dateRangeLength) {
        this.sliderDate++;
      }
      if (event === "backward" && this.sliderDate > 0) {
        this.sliderDate--;
      }
      if (event === "stop") {
        this.sliderDate = 0;
        this.sliderButtonPress("pause");
      }
    },
    resetFloormap: function() {
      this.sliderButtonPress("pause");
      this.viewHistoricalController = false;
      this.unitUpdated();
    },
    fetchHistoricalFloorMapCases: async function() {
      const start = moment(this.historicalDateRange[0]).toDate();
      const to = moment(this.historicalDateRange[1])
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate();
      const params = {
        page: 1,
        size: 1000,
        unitIds: [this.selectedUnit],
        isFloorMap: true,
        infectionSites: ["GI", "Respiratory", "UTI", "Skin", "SSI"],
        inclusiveOnsetDateFrom: start,
        inclusiveOnsetDateTo: to
      };
      if (this.filteredSiteSubType && this.filteredSiteSubType !== 0) {
        params.infectionSiteSubTypes = [this.filteredSiteSubType];
      }
      const response = await this.$http.post(`${window.CONFIG.infection_api}/search`, params);
      return response;
    },
    startHistoricalFloormap: async function() {
      console.log("startHistoricalFloormap");
      this.sliderButtonPress("pause");
      var after = moment(this.historicalDateRange[0]).toDate();
      var before = moment(this.historicalDateRange[1]);
      this.dateRangeLength = before.diff(after, "days");
      this.historicalFloorMapVisible = false;
      this.viewHistoricalController = true;
      this.initialCaseDict = {};
      var vm = this;

      const casesResponse = await this.fetchHistoricalFloorMapCases();
      if (casesResponse.body.totalElements) {
        const cases = casesResponse.body?.content.map(transformer.mapInfectionCaseToLegacyModel);
        cases.forEach(c => {
          if (!this.initialCaseDict[c.bedId]) {
            this.initialCaseDict[c.bedId] = [c];
          } else {
            this.initialCaseDict[c.bedId].push(c);
          }
        });
      } else {
        console.log("no content");
        vm.cases = [];
      }

      const response = await this.$http.get(`${window.CONFIG.api}/units/${this.selectedUnit}`);
      if (response.body) {
        vm.selectedUnitDetail = response.body;
      } else {
        console.log("no unit detail response");
      }
      console.log(vm.selectedUnitDetail);
      this.sliderDate = 0;
      this.sliderButtonPress("play");
    },
    displayHistoricalFloormap: async function(dateValue) {
      let floorMap = this.$refs.floormap;
      let vm = this;
      let currentUnitDetails = {};
      currentUnitDetails = JSON.parse(JSON.stringify(vm.selectedUnitDetail));

      // sort by onset and resolution date
      const currentSliderDate = moment(this.historicalDateRange[0])
        .add(dateValue, "days")
        .hours(23)
        .minutes(59)
        .seconds(59);
      let currentCases = {};
      console.log("slider date " + moment(currentSliderDate).toDate());
      for (let [bedId, cases] of Object.entries(vm.initialCaseDict)) {
        const relevantCases = this.findRelevantCases([...cases], currentSliderDate);
        currentCases[bedId] = relevantCases;
      }
      for (let r of currentUnitDetails.rooms) {
        for (let b of r.beds) {
          b.cases = [];
          if (currentCases[b.id] && currentCases[b.id].length > 0) {
            b.client = currentCases[b.id][0].client;
            b.photo = vm.clientPhoto(b.client);
            b.cases = [...currentCases[b.id]];
          }
        }
      }
      console.log(currentUnitDetails);
      floorMap.unit = currentUnitDetails;
    },
    findRelevantCases(cases, currentDate) {
      // filter out resolved cases where resolutionDate is before currentDate
      const activeCases = cases.filter(c => {
        if (c.resolutionDate) {
          return moment(c.resolutionDate).isSameOrAfter(currentDate);
        }
        return true;
      });

      // find case with onSetDate closest to currentDate
      const closestCase = activeCases.reduce((closest, currentCase) => {
        const currentCaseDate = moment(currentCase.onSetDate);
        if (currentCaseDate.isSameOrBefore(currentDate)) {
          if (!closest) return currentCase;
          const closestDate = moment(closest.onSetDate);
          return currentCaseDate.isAfter(closestDate) ? currentCase : closest;
        }
        return closest;
      }, null);

      // filter active cases array only include cases with the same clientId as the closest case
      const resultCases = closestCase ? activeCases.filter(c => c.clientId === closestCase.clientId) : [];
      return resultCases;
    },
    updateFloormap: async function() {
      console.log("updateFloormap");
      console.log(this.selectedUnit);
      console.log(this.sites);
      let floorMap = this.$refs.floormap;
      let vm = this;
      let u = this.selectedUnit;
      if (floorMap && u) {
        this.$http.get(window.CONFIG.api + "/unit/" + u + "/mapimage/base64").then(async function(response) {
          floorMap.image = "data:image/png;base64," + response.bodyText;
          if (!vm.selectedUnitDetail || u !== vm.selectedUnitDetail.id) {
            if (vm.unitMap[u] && vm.unitMap[u].rooms) {
              vm.selectedUnitDetail = vm.unitMap[u];
            } else {
              let response = await this.$http.get(`${window.CONFIG.api}/units/${u}`);
              if (response.body) {
                vm.unitMap[u] = response.body;
                vm.selectedUnitDetail = response.body;
              }
            }
          }
          for (let r of vm.selectedUnitDetail.rooms) {
            for (let b of r.beds) {
              if (b.occId) {
                b.client = vm.clients[b.occId];
                b.photo = vm.clientPhoto(b.client);
                let response = await this.$http.get(`${window.CONFIG.api}/clients/${b.occId}/symptomDetails?resolved=false`);
                b.cases = response.body || null;
                if (b.cases) {
                  console.log(JSON.stringify(b.cases, " ", 2));
                }
              }
            }
          }
          floorMap.unit = vm.selectedUnitDetail;
          vm.$nextTick(function() {
            vm.loading = false;
            vm.firstLoad = true;
            console.log("show loading", vm.loading);
          });
        });
      }
    },
    filterUpdated: function(o) {
      this.selectedUnit = o.unit;
      this.selectedSite = o.site;
      this.availableUnits = o.units || [];
      this.availableFacs = o.sites ? o.sites : 0;
      this.loadCases();
    },
    updatePersonalNotes(newNote) {
      console.log(newNote);
      if (this.clients[this.selectedClient.clientId]) {
        var clientData = this.clients[this.selectedClient.clientId];
        clientData.notes.splice(0, 0, newNote);
      }
    },
    handleChange(value) {
      // console.log(value)
      this.filteredSite = value[0];
      this.filteredSiteSubType = value[1] || 0;

      this.symptomTypeUpdated(value[0]);
    },
    getsubStatusMap: function(caseData) {
      // console.log(caseData)
      if (caseData && caseData.symptomType && caseData.symptomType.subTypes) {
        // console.log(this.subStatusMap[caseData.symptomType.text])
        // console.log('getsubStatusMap subtypes')
        return caseData.symptomType.subTypes;
      }

      // console.log('getsubStatusMap')
      return [];
    },
    buildParam: function() {
      var after = this.filterDateRange[0] || new Date();
      var before = this.filterDateRange[1] || new Date();

      after = moment(after)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .toDate();
      before = moment(before)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toDate();

      var rUrl = "start=" + encodeURIComponent(after) + "&end=" + encodeURIComponent(before);
      rUrl = rUrl + "&types=" + this.filterSymptomTypes;

      if (this.filteredSiteSubType !== 0) {
        rUrl = rUrl + "&subType=" + this.filteredSiteSubType;
      }

      if (this.selectedOrigin !== 0) {
        rUrl = rUrl + "&origin=" + this.selectedOrigin;
      }

      if (this.selectedStatus !== 0) {
        rUrl = rUrl + "&confirmed=" + (this.selectedStatus === 1 ? "true" : "false");
      }

      if (this.selectedPrecautions !== 0) {
        rUrl = rUrl + "&precaution=" + this.selectedPrecautions;
      }

      if (this.selectedOrganisms !== 0) {
        rUrl = rUrl + "&organism=" + this.selectedOrganisms;
      }

      if (this.selectedResolved !== 0) {
        rUrl = rUrl + "&resolved=" + (this.selectedResolved === 1 ? "true" : "false");
      }

      if (this.selectedFamilyNotified !== 0) {
        rUrl = rUrl + "&familyNotified=" + (this.selectedFamilyNotified === 1 ? "true" : "false");
      }

      if (this.selectedNextOfNotified !== 0) {
        rUrl = rUrl + "&nextOfNotified=" + (this.selectedNextOfNotified === 1 ? "true" : "false");
      }

      if (this.selectedPhysicianNotified !== 0) {
        rUrl = rUrl + "&physicianNotified=" + (this.selectedPhysicianNotified === 1 ? "true" : "false");
      }

      if (this.selectedClientType !== 0) {
        rUrl = rUrl + "&clientType=" + (this.selectedClientType === 1 ? "Client" : "Staff");
      }

      if (this.selectedFirstName !== "") {
        rUrl = rUrl + "&firstName=" + this.selectedFirstName;
      }

      if (this.selectedLastName !== "") {
        rUrl = rUrl + "&lastName=" + this.selectedLastName;
      }

      if (this.selectedTreatment !== 0) {
        rUrl = rUrl + "&treatmentId=" + this.selectedTreatment;
      }

      if (this.selectedClientStatus !== 0) {
        rUrl = rUrl + "&clientActive=" + (this.selectedClientStatus === 1 ? "true" : "false");
      }

      if (Array.isArray(this.availableUnits) && this.availableUnits.length > 0) {
        rUrl = rUrl + "&units=" + this.availableUnits;
      }

      if (this.selectedSite > 0) {
        rUrl = rUrl + "&facIds=" + [this.selectedSite];
      } else if (this.availableFacs !== 0 && Array.isArray(this.availableFacs)) {
        rUrl = rUrl + "&facIds=" + this.availableFacs;
      }

      console.log(rUrl);
      return rUrl;
    },
    loadCases: function(callback) {
      console.log("loadCases");
      if (!this.loading) {
        this.loading = true;
        console.log("show loading", this.loading);
      }

      if (this.selectedUnit === 0) {
        this.loading = false;
        console.log("show loading", this.loading);
        return;
      }

      if (callback) {
        callback();
      }
      // var rUrl = `${window.CONFIG.api}/symptomDetail/unit/0?resolved=false&web=true&clientActive=true&clientType=Client&facIds=${facId}`
      // rUrl += this.filterDateRange[0] ? `&after=${this.filterDateRange[0]}` : ''
      // rUrl += this.filterDateRange[1] ? `&before=${this.filterDateRange[1]}` : ''
      // let infCases = {}
      // this.$http.get(rUrl)
      // .then(response => {
      //   console.log(response.body)
      //   if (response.body) {
      //     var cases = response.body || [] // response.body['_embedded'].symptomDetails
      //     let caseTotal = cases.length
      //     let processed = 0
      //     cases.forEach(function (c) {
      //       if (c.client.bed) {
      //         infCases[`${c.client.id}`] = infCases[`${c.client.id}`] || []
      //         infCases[`${c.client.id}`].push(c)
      //         if (c.origin == null) {
      //           c.origin = 'Not Selected'
      //         }
      //         c.symptoms.sort(function (a, b) {
      //           return a.colorCode > b.colorCode
      //         })
      //         c.otherSymptoms = []
      //         api.getCustomSymptomForDetail(c.id, (r) => {
      //           c.otherSymptoms = r.body.otherSymptoms || []
      //           processed = processed + 1

      //           if (processed === caseTotal) {
      //             vm.cases = cases
      //           }
      //         })
      //       }
      //     })
      //   } else {
      //     vm.cases = []
      //   }
      //   vm.infCases = infCases
      //   vm.$nextTick(function () {
      //     vm.loading = false
      //     vm.firstLoad = true
      //     console.log('show loading', vm.loading)
      //   })

      //   if (callback) {
      //     callback()
      //   }
      // }, response => {
      //   // error callback
      // })
    },
    clientName: function(symptomDetail) {
      var clientData = symptomDetail.client;
      this.clients[symptomDetail.clientId] = clientData;
      return this.getClientName(clientData);
    },
    getClientName: function(clientData) {
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      // console.log(JSON.stringify(clientData, ' ', 2))
      return firstName + middleName + lastName;
    },
    nameFilter: function(cases) {
      var vm = this;
      return cases.filter(function(detail) {
        return detail.name.toLowerCase().includes(vm.searchNameInput.toLowerCase());
      });
    },
    reloadPage: function(page) {
      if (this.symptomDetailPage !== page) {
        this.symptomDetailPage = page;
        if (this.firstLoad) {
          this.loadCases();
        }
      }
    },
    loadImage: function(detail, callback) {
      var vm = this;
      if (this.clients[detail.clientId] || this.clients[detail.clientId] === false) {
        callback();
        return;
      }
      this.clients[detail.clientId] = false;
      this.$http.get(window.CONFIG.api + "/clients/" + detail.clientId).then(
        response => {
          // console.log(JSON.stringify(response.body, ' ', 2))
          vm.$set(vm.clients, detail.clientId, response.body);
          vm.$forceUpdate();
          callback();
        },
        response => {
          // error
          callback();
        }
      );
    },
    statusImage: function(detail) {
      var result = "";
      var filename;
      if (detail.symptomType) {
        filename = this.statusImageMap[detail.symptomType];
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      if (result === "") {
        filename = "bug4";
        if (!detail.confirmed) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      return result;
    },
    tagType: function(type) {
      if (type.colorCode === "0") {
        return "success";
      } else if (type.colorCode === "1") {
        return "primary";
      } else if (type.colorCode === "2") {
        return "warning";
      } else if (type.colorCode === "3") {
        return "danger";
      }
      return "gray";
    },
    selectedRow: function(row, event, column) {
      if (column.property === "active") {
        return;
      }

      this.symptoms = JSON.parse(JSON.stringify(this.symptoms));
      this.selectedCase = JSON.parse(JSON.stringify(row));
      this.selectedClient = this.selectedCase.client;
      if (!this.selectedCase.outcome) {
        this.selectedCase.outcome = 0;
      }

      if (!this.selectedCase.origin) {
        this.selectedCase.origin = "Not Selected";
      }

      var vm = this;
      vm.caseActiveTab = "1";
      vm.$nextTick(function() {
        // console.log(vm.caseActiveTab)
        vm.dialogFormVisible = true;
      });
    },
    dateRangeUpdated: function(value) {
      let end = new Date();
      let start = new Date();
      if (!value) {
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      } else {
        start = moment(value.split("~")[0].trim())
          .hour(0)
          .minute(0)
          .second(0)
          .toDate();
        end = moment(value.split("~")[1].trim())
          .hour(23)
          .minute(59)
          .second(59)
          .toDate();
      }
      this.filterDateRange = [start, end];

      if (this.firstLoad) {
        this.loadCases();
      } else {
        this.loadCases(() => {
          this.updateFloormap();
        });
      }
    },
    unitUpdated: function(value) {
      console.log("unitUpdated");
      this.loadCases(() => {
        this.updateFloormap();
      });
    },
    originUpdated: function(value) {
      if (value === 0) {
        this.filterOrigin = null;
      } else {
        this.filterOrigin = value;
      }
      // console.log('originUpdated:' + value)
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    precautionUpdated: function(value) {
      // console.log('precautionUpdated: ', +value)
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    organismsUpdated: function(value) {
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    resolvedUpdated: function(value) {
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    confirmStatusUpdated: function(value) {
      // console.log('confirmStatusUpdated:' + value)
      if (value === 0) {
        this.filterConfirmStatus = null;
      } else {
        this.filterConfirmStatus = value === 1 ? "true" : "false";
      }

      if (this.firstLoad) {
        this.loadCases();
      }
    },
    symptomTypeUpdated: function(value) {
      var vm = this;
      var sypmtomTypeFilter = [];
      // this.filteredSiteSubType = 0
      if (this.symptomTypes) {
        this.symptomTypes.forEach(function(s, i) {
          if (vm.filteredSite === 0 || vm.filteredSite.type === s.type) {
            sypmtomTypeFilter.push(s.type);
          }
        });
      }

      this.filterSymptomTypes = sypmtomTypeFilter;
      // console.log(JSON.stringify(this.filterSymptomTypes))
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    symptomSubTypeUpdated: function(value) {
      if (this.firstLoad) {
        this.loadCases();
      }
    },
    organismById: function(organId) {
      var organName = "Not Selected";
      if (organId === 0) {
        return organName;
      }

      this.organisms.forEach(function(o) {
        if (o.id === organId) {
          organName = o.name;
        }
      });

      return organName;
    },
    treatmentById: function(treatmentId) {
      var name = "Not Selected";
      if (treatmentId === 0) {
        return name;
      }

      this.treatments.forEach(function(t) {
        if (t.id === treatmentId) {
          name = t.displayText;
        }
      });

      return name;
    },
    unitById: function(unitId) {
      var unitName = "";
      this.units.forEach(function(u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    originById: function(originId) {
      var originName = "Not Selected";
      this.origins.forEach(function(o) {
        if (o.value === originId && o.value !== 0) {
          originName = o.label;
        }
      });
      return originName;
    },
    unitByName: function(unitName) {
      var unit = null;
      this.units.forEach(function(u) {
        if (u.displayText === unitName) {
          unit = u;
        }
      });

      return unit;
    },
    precautionById: function(precautionId) {
      var name = "";
      this.precautions.forEach(function(p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    clientPhoto: function(client) {
      if (client && client.photo) {
        var f = client.photo;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "/static/" + (client.photo ? client.photo : "user") + ".png";
      }

      return "/static/user.png";
    },
    canSave: function(symptomDetail) {
      var canSave = false;
      symptomDetail.symptoms.forEach(function(s) {
        if (symptomDetail.symptomType === s.classType) {
          canSave = true;
        } else if (symptomDetail.infectionSite === s.classType) {
          canSave = true;
        }

        // console.log('- canSave ' + symptomDetail.symptomType + '- ' + s.classType)
        // console.log(symptomDetail)
        // console.log(s)
        // console.log('symptomDetail.infectionSite: ' + symptomDetail.infectionSite)
      });

      // console.log('canSave ' + (canSave ? 'Yes' : 'NO'))

      return canSave;
    },
    saveSymtomDetail: function(symptomDetail, callback) {
      var vm = this;
      console.log(symptomDetail.otherSymptoms);
      let otherSymptoms = symptomDetail.otherSymptoms ? symptomDetail.otherSymptoms.slice() : [];
      let sId = symptomDetail.id;

      api.updateSymptomDetail(symptomDetail, function() {
        api.createCustomSymptomsExt(
          {
            symptomDetailId: sId,
            otherSymptoms: otherSymptoms
          },
          r => {
            vm.loadCases();
            vm.dialogFormVisible = false;
            vm.selectedCase = null;
            console.log(r);
            if (callback) {
              callback();
            }
          }
        );
      });
    },
    reverse: function(array) {
      return array.slice().reverse();
    },
    isStaff: function(client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    exportExcel: function() {
      var loadingInstance = Loading.service({ fullscreen: true });
      console.log("show loading");
      // console.log('exportExcel clicked')
      var unitId = this.selectedUnit;
      var rUrl = window.CONFIG.api + "/symptomDetail/unit/" + unitId + "/export/excel/" + "?" + this.buildParam();

      this.$http.get(rUrl, { responseType: "arraybuffer" }).then(response => {
        // console.log(JSON.stringify(response, ' ', 2))
        var headers = response.headers.map;
        var blob = new window.Blob([response.data], { type: headers["content-type"] });

        // IE
        if (window.navigator.msSaveOrOpenBlob) {
          console.log("IE detected");
          window.navigator.msSaveOrOpenBlob(blob, "report.xls");
          setTimeout(function() {
            loadingInstance.close();
          }, 100);
        } else {
          // Chrome and safari
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report.xls";
          link.click();
          loadingInstance.close();
        }
      });
    },
    createNewCase: function() {
      this.newCaseDialogFormVisible = true;
      this.newCaseDialogFormVisible = false;
      this.newCase = {
        client: null,
        location: null,
        symptoms: [],
        symptomType: null,
        origin: "",
        subtypeString: "",
        onSetDate: new Date(),
        confirmed: false,
        error: null,
        primarySelection: ""
      };
      this.newCaseDialogFormVisible = true;
    },
    addSymptoms: function(newCase) {
      var vm = this;
      if (!newCase.client) {
        vm.newCase.error = "Please choose resident.";
        return;
      }

      if (!newCase.symptomType) {
        vm.newCase.error = "No infection site selected.";
        return;
      }
      var symptomList = [];
      var customSymptomList = [];
      var validSymptomList = !newCase.symptomType.requireSymptoms;
      if (validSymptomList == null) {
        return;
      }
      var loadingInstance = Loading.service({ fullscreen: true });
      console.log("show loading");
      var first = true;
      this.symptoms.forEach(function(d) {
        newCase.symptoms.forEach(function(s) {
          if (s.custom) {
            if (first) {
              customSymptomList.push(s);
            }
          } else if (d.id === s.id) {
            symptomList.push(d);
            if (d.classType === newCase.symptomType.type) {
              validSymptomList = true;
            }
          }
        });
        first = false;
      });

      if (!validSymptomList) {
        vm.newCase.error = "At least one " + vm.newCase.symptomType.displayText + " symptom is required.";
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return;
      }
      // console.log(this.clientList)
      var newSymptomDetail = {
        facId: 0,
        unitId: newCase.client.unitId,
        bedId: 0,
        physicianId: 0,
        onSetDate: newCase.onSetDate.getTime(),
        symptoms: symptomList,
        symptomType: newCase.symptomType.type,
        status: null,
        clientId: newCase.client.id,
        client: newCase.client,
        organismId: 0,
        treatmentId: 0,
        resolved: false,
        confirmed: newCase.confirmed,
        active: true,
        familyNotified: false,
        nextOfNotified: false,
        physicianNotified: false,
        origin: newCase.origin,
        notes: [],
        subtypeString: newCase.subtypeString
      };

      if (!newCase.symptomType.allowedToMobile) {
        newSymptomDetail.symptomType = null;
        newSymptomDetail.infectionSite = newCase.symptomType.type;
      }

      // delete newSymptomDetail.clientId
      newSymptomDetail.symptoms.forEach(s => {
        delete s._links;
      });

      var jsonData = JSON.stringify(newSymptomDetail, 2, " ");
      // console.log('-------------!!!')
      // console.log(jsonData)
      this.$http.post(window.CONFIG.api + "/client/" + newCase.client.id + "/symptom/detail/new", jsonData).then(
        response => {
          console.log(JSON.stringify(response, 2, " "));
          var jsonData = response.body;
          let detailId = jsonData.id;
          let otherSymptoms = customSymptomList || [];
          api.createCustomSymptomsExt(
            {
              symptomDetailId: detailId,
              otherSymptoms: otherSymptoms
            },
            r => {
              console.log(r);
            }
          );
          jsonData.onSetDate = newSymptomDetail.onSetDate;
          if (newCase.primarySelection) {
            jsonData.organismId = newCase.primarySelection.id;
            jsonData.organismDetails = [{ type: "Primary", organism: newCase.primarySelection }];
          }

          vm.saveSymtomDetail(jsonData, function() {
            vm.createNewCase();
            vm.newCaseDialogFormVisible = false;
            loadingInstance.close();
          });
        },
        response => {
          // error callback
          // console.log(JSON.stringify(response, 2, ' '))
          if (response.status === 409) {
            vm.newCase.error = (vm.newCase.symptomType.text || vm.newCase.symptomType.displayText) + " case already exsits for " + vm.getClientName(vm.newCase.client);
          }
          loadingInstance.close();
        }
      );
    },
    deleteCase: function(i, row) {
      // console.log(row)
      // if (!row) {
      //   row = this.cases[i]
      // }
      this.selectedCaseToDelete = row;
      this.dialogConfirmDeleteVisible = true;
    },
    confirmDeleteCase: function() {
      if (this.selectedCaseToDelete) {
        this.selectedCaseToDelete.active = false;
        this.saveSymtomDetail(this.selectedCaseToDelete);
        this.dialogConfirmDeleteVisible = false;
      }
    },
    newSypmtomsSelected: function() {
      console.log(JSON.stringify(this.newCase.symptoms));
      // console.log(tagsEl)
      // console.log(tagsEl.nodeName)
      // console.log(tagsEl.children)

      var vm = this;
      this.$nextTick(function() {
        console.log(" --- this.$refs ---");
        console.log(vm.$refs);

        var tagsEl = vm.$refs.newcasesymptoms.$refs.tags.firstChild;
        for (var i = 0; i < tagsEl.children.length; i++) {
          var el = tagsEl.children[i];
          console.log(el.textContent);
          for (var j = 0; j < vm.symptoms.length; j++) {
            var s = vm.symptoms[j];
            if (s.custom) {
              console.log("custom skipped");
              continue;
            }
            if (el.textContent === s.displayText) {
              if (el.classList.contains("el-tag--primary")) {
                el.classList.remove("el-tag--primary");
              }
              var tagType = "el-tag--" + vm.tagType(s);
              // console.log(tagType)
              if (!el.classList.contains(tagType)) {
                el.classList.add(tagType);
              }
              break;
            }
          }
          // console.log(vm.symptoms)
        }
      });
    },
    locationFilterUpdate: function(...args) {
      const selectedUnit = this.units.filter(u => u.id === args[0].unit);
      if (selectedUnit[0]) {
        this.selectedUnit = selectedUnit[0].id;
        this.selectedSite = selectedUnit[0].siteId;
        this.loadCases(() => {
          this.updateFloormap();
        });
      }
    }
  },
  mounted: function() {
    this.clientList = this.$configStore.data.clientList || [];
    this.organization = this.$configStore.data.organization || [];
    if (this.organization[0]) {
      this.sites = this.organization[0].sites;
    } else {
      this.organization = [];
      this.sites = [];
      this.loading = false;
      console.log("show loading", this.loading);
    }
    this.dataReady = true;
    var vm = this;
    vm.sites = this.$configStore.data.sites || [];
    vm.units = this.$configStore.data.units || [];
    vm.rooms = this.$configStore.data.rooms || [];
    vm.beds = this.$configStore.data.beds || [];
    vm.symptomTypes = this.$configStore.data.symptomTypes || [];
    // vm.customSymptomTypeMap = this.$configStore.data.customSymptomTypeMap || {}
    vm.symtpoms = this.$configStore.data.symtpoms || [];
    vm.organisms = this.$configStore.data.organisms || [];
    vm.precautions = this.$configStore.data.precautions || [];
    vm.treatments = this.$configStore.data.treatments || [];
    vm.origins = this.$configStore.data.origins || [];
    vm.loadCases();
    this.ready = true;
  },
  destroyed: function() {
    this.sliderButtonPress("pause");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .filter-section > label {
  display: inline-block;
  flex: 1 1 auto;
  width: 300px;
}

::v-deep .filter-title-label {
  margin-left: 1em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}

.floor-map {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.floor-map .filter,
.legend {
  flex: 0 1 auto;
}

.floor-map .floor {
  flex: 1 1 auto;
}
.floor-map .floor hcx-floormap {
  height: 100%;
  display: block;
}
.floor-map .floor hcx-floormap .floor-map {
  height: 100% !important;
  min-height: 600px;
  width: 100% !important;
  min-width: 600px;
}

.floor-map .legend {
  display: flex;
  width: 50%;
  margin: 0 auto 5px;
  justify-content: center;
  margin-top: 15px;
}
.floor-map .legend > div {
  margin-right: 10px;
}
.floor-map .legend div::before {
  display: inline-block;
  content: "";
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.floor-map .legend .uti::before {
  background-color: var(--infection-site-color-code-uti);
}
.floor-map .legend .respiratory::before {
  background-color: var(--infection-site-color-code-respiratory);
}
.floor-map .legend .gi::before {
  background-color: var(--infection-site-color-code-gi);
}
.floor-map .legend .skin::before {
  background-color: var(--infection-site-color-code-skin);
}
.controller {
  border: 1px solid #a9a9a9;
  width: 900px;
  text-align: center;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: -5px;
  padding-top: 10px;
}
.historicalSettings {
  float: left;
  margin-left: 20px;
  font-size: 15px;
}

.controller-buttons {
  padding: 5px;
  float: left;
}

.current-date-display {
  margin-left: -160px;
}

.close-historical-button {
  float: right;
  margin-right: 15px;
  padding: 0;
}

.historicalButtonDiv {
  position: absolute;
  top: 10px;
  right: -300px;
}

.edge-dates {
  margin-top: 7px;
}
.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.new-case-dialog div {
  line-height: 3em;
}

.delete-dialog div {
  line-height: 3em;
}

.delete-dialog .el-dialog div.el-dialog__body {
  padding-top: 0;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}
.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.el-popover .el-row {
  line-height: 2.5em;
  font-family: sans-serif;
  font-size: 1.2em;
}

.el-popover .el-row .el-switch {
  float: right;
}

.dialog-footer {
  float: right;
  margin: 1em;
  margin-top: 2em;
}
</style>

<style>
.floor hcx-floormap .floor-map {
  margin: auto;
  height: 95%;
  width: 50%;
}

.floor hcx-floormap .leaflet-pane {
  transform: scale(1.2) translate(-50px, -50px);
}
</style>