<template>
  <div class="precautions-tab">
    <table-heading :title="linked.title">
      <template slot="right-alignment">
        <el-pagination
          @size-change="
            (size) => {
              linked.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              linked.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="linked.pagination.size"
          :page-count="linked.totalPages"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </template>
    </table-heading>

    <el-table class="linked-table" :data="linked.list" v-loading="linked.loading">
      <el-table-column label="Precautions" min-width="200">
        <template scope="scope">
          {{ getPrecautionName(scope.row.precautionId) }}
          <el-tag type="danger" v-if="precautionIsActive(scope.row)">active</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Start Date" min-width="150">
        <template scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>

      <el-table-column label="End Date" min-width="150">
        <template scope="scope">
          {{ formatDate(scope.row.endDate) }}
        </template>
      </el-table-column>

      <el-table-column label="Created By" min-width="150">
        <template scope="scope">
          {{ scope.row.createdBy }}
        </template>
      </el-table-column>

      <el-table-column label="Actions" v-if="!isReadOnly" width="100" align="left" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button @click="editPrecaution(scope.row)" size="small">
              <Icon :iconKey="'edit'" :description="'Edit'" />
            </el-button>
            <el-button @click="deletePrecaution(scope.row.id)" size="small">
              <Icon :iconKey="'delete'" :description="'Delete'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <table-heading :title="'Precautions History'" v-if="!showOnlyActiveLinked">
      <template slot="right-alignment">
        <el-button v-if="!isReadOnly" @click="createPrecaution()">+ New Precaution</el-button>
        <el-pagination
          @size-change="
            (size) => {
              history.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              history.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="history.pagination.size"
          :page-count="history.totalPages"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </template>
    </table-heading>

    <el-table v-if="!showOnlyActiveLinked" class="history-table" :data="history.list" v-loading="history.loading">
      <el-table-column label="Precautions" min-width="200">
        <template scope="scope">
          {{ getPrecautionName(scope.row.precautionId) }}
          <el-tag type="danger" v-if="precautionIsActive(scope.row)">active</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Start Date" min-width="150">
        <template scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>

      <el-table-column label="End Date" min-width="150">
        <template scope="scope">
          {{ formatDate(scope.row.endDate) }}
        </template>
      </el-table-column>

      <el-table-column label="Created By" prop="createdBy" min-width="150">
        <template scope="scope">
          {{ scope.row.createdBy }}
        </template>
      </el-table-column>

      <el-table-column label="Actions" v-if="!isReadOnly" width="100" align="left" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button @click="editPrecaution(scope.row)" size="small">
              <Icon :iconKey="'edit'" :description="'Edit'" />
            </el-button>
            <el-button @click="deletePrecaution(scope.row.id)" size="small">
              <Icon :iconKey="'delete'" :description="'Delete'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    
    <precaution-editor v-if="showEditor" v-on:editor-close="cancelEditor" v-on:editor-save="editorComplete" :precautions="precautions" :precautionDetail="selectedPrecaution" :infectionCase="caseData" />
  </div>
</template>

<script>
import auth from "../../auth";
import util from "../../util";
import moment from "moment";

import CaseDetailHeading from "../Shared/CaseDetailTableHeading";
import PrecautionEditor from "./Editor.vue";
import Icon from "../Shared/Icon";

export default {
  name: "treatments",
  props: ["caseData", "clientData", "precautions", "showOnlyActiveLinked"],
  components: {
    "table-heading": CaseDetailHeading,
    "precaution-editor": PrecautionEditor,
    Icon,
  },
  watch: {
    "linked.pagination.page": function () {
      this.updateLinked();
    },
    "linked.pagination.size": function () {
      this.updateLinked();
    },
    "history.pagination.page": function () {
      this.updateHistory();
    },
    "history.pagination.size": function () {
      this.updateHistory();
    },
  },
  data() {
    return {
      linked: {
        title: "Precautions - Infection Case",
        list: [],
        loading: true,
        pagination: {
          page: 1,
          size: 5,
        },
        totalPages: 0,
      },
      history: {
        list: [],
        loading: true,
        pagination: {
          page: 1,
          size: 5,
        },
        totalPages: 0,
      },
      isReadOnly: false,
      showEditor: false,
      selectedPrecaution: null,
      linkedPrecautions: [],
      precautionsHistory: [],
      precautionsLookup: {},
    };
  },
  methods: {
    async resolveAllPrecautions() {
      try {
        for (const precaution of this.linked.list) {
          const updatedPrecaution = {
            ...precaution,
            endDate: moment().format("YYYY-MM-DDTHH:mm:ss")
          };
          
          await this.$http
          .patch(
            `${window.CONFIG.precaution_api}/${precaution.id}`, 
            JSON.stringify(updatedPrecaution)
          );
        }
        
        await this.updateLinked();
        this.$message({
          type: 'success',
          message: 'All precautions have been ended'
        });
        this.$emit("resolution-close");
      } catch (error) {
        await this.updateLinked();
        this.$message({
          type: 'error',
          message: 'Failed to end all precautions'
        });
      }
    },
    createPrecaution() {
      this.showEditor = true;
    },
    editPrecaution(record) {
      this.selectedPrecaution = record;
      this.showEditor = true;
    },
    async deletePrecaution(id) {
      this.$confirm("Delete this record?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          await this.$http.delete(`${window.CONFIG.precaution_api}/${id}`);
          this.showOnlyActiveLinked ? this.updateLinked() : this.updatePrecautions();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    cancelEditor() {
      this.showEditor = false;
      this.selectedPrecaution = null;
    },
    editorComplete() {
      this.showEditor = false;
      this.selectedPrecaution = null;
      this.showOnlyActiveLinked ? this.updateLinked() : this.updatePrecautions();
    },
    formatDate(timestamp) {
      return timestamp ? moment(timestamp).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
    },
    getPrecautionName(id) {
      return this.precautionsLookup[id]?.displayText || "";
    },
    precautionIsActive(row) {
      const { startDate, endDate } = row;
      // precaution is active when there's no end date
      if (!endDate) {
        return true;
      }
      // also active if between the start and end dates
      const now = new Date();
      return now >= new Date(startDate) && now <= new Date(endDate);
    },
    async updateLinked() {
      const symptomDetailId = this.caseData.id;
      return this.$http
        .get(window.CONFIG.precaution_api, {
          params: {
            clientId: this.caseData.client.id,
            ...this.linked.pagination,
            symptomDetailId,
          },
        })
        .then((resp) => resp.json())
        .then((json) => {
          this.linked.list = this.showOnlyActiveLinked ? json.content.filter(this.precautionIsActive) : json.content;
          this.linked.totalPages = json.totalPages;
        })
        .then(() => (this.linked.loading = false));
    },
    async updateHistory() {
      this.history.loading = true;
      return this.$http
        .get(window.CONFIG.precaution_api, {
          params: {
            clientId: this.caseData.client.id,
            ...this.history.pagination,
          },
        })
        .then((resp) => resp.json())
        .then((json) => {
          this.history.list = json.content;
          this.history.totalPages = json.totalPages;
        })
        .then(() => (this.history.loading = false));
    },
    async updatePrecautions() {
      await this.updateLinked();
      await this.updateHistory();
    },
  },
  async mounted() {
    // TODO: fix edit permissions
    this.isReadOnly = !(auth.userInfo().roles.includes("TRT_UPDATE") && auth.userInfo().roles.includes("TRT_CREATE") && auth.userInfo().roles.includes("TRT_DELETE"));
    this.precautionsLookup = util.arrayToObj(this.precautions, "id");
    this.showOnlyActiveLinked ? this.updateLinked() : this.updatePrecautions();
    // this component doubles as a tab within surveillance as well as a table displaying open precautions when resolving an infection case
    this.linked.title = this.showOnlyActiveLinked ? "The following precautions are still open, do you wish to close them?" : "Precautions - Infection Case";
  },
};
</script>
<style scoped>
.linked-table {
  margin-bottom: 30px;
}
</style>
